import moment from 'moment';

let timezoneOffset = 0;

export default {

    /**
     * Format the date in the active locale or return an empty string if the date is null.
     *
     * @param {Date|string|null} date The date/time to format, eg "19:45" or "2020-01-07T17:45:00.000Z" or Date object
     * @param {string} format Moment-js date format https://momentjs.com/docs/#/displaying/
     * @param {string} locale eg nl-NL
     * @return {string} The formatted date
     */
    dateFormat: (date: Date|string|null, format: string, locale: string) => {
        if (!format) {
            throw new Error(`Format is required "${format}"`);
        }
        if (!locale) {
            throw new Error(`Locale is required "${locale}"`);
        }
        if (!/^[a-z]{2}-[A-Z]{2}$/.test(locale)) {
            throw new Error(`Locale is invalid "${locale}"`);
        }
        if (!date) {
            return '';
        }

        let result;

        // Detect time format; eg 19:45
        if ((date as string).length && /^[0-2]\d:[0-5]\d$/g.test(date as string)) {
            // We accept time as string for now.
            const momentObject = moment(date, 'HH:mm');
            momentObject.add(timezoneOffset, 'minutes');
            result = momentObject.locale(locale).format(format);
        } else {
            // Let momentJS figure out the date format; eg 2020-01-07T17:45:00.000Z
            const momentObject = moment(date);
            momentObject.add(timezoneOffset, 'minutes');
            result = momentObject.locale(locale).format(format);
        }

        // Date appears to be invalid.
        if (!result || result === 'Invalid date') {
            return `Invalid date "${date}"`;
        }

        return result;
    },

    /**
     * Set the global timezone offset for displaying time.
     *
     * @param {number} customTimezoneOffset Number of minutes to add to all times
     */
    setTimezoneOffset(customTimezoneOffset: number) {
        timezoneOffset = customTimezoneOffset;
    }
};
