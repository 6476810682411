
import {defineComponent} from 'vue';
import io from 'socket.io-client';
import axios from 'axios';
import NetworkServices from '../services/NetworkServices';
import DateFormatFilter from '../components/DateFormatFilter';
import UtilityServices from '../services/UtilityServices';
import OrderServices from '../services/OrderServices';
import {WaitingTime} from '@/interfaces/waiting-time';

export default defineComponent({

    components: {},
    props: {},

    data: function () {
        return {
            waitingTime: null as WaitingTime | null,
            waitingTimeLogging: null as string | null,
            errorMessage: null as string | null,
            darkMode: false,
            editWaitingTime: false,
            customWaitingTime: '',
            socket: null as any
        };
    },
    methods: {

        /**
         * Initialize client socket and start listening for change messages.
         *
         * @return {Socket}
         */
        createSocket() {
            const socket = io();
            socket.on('waitingTime',
                (waitingTime: WaitingTime) => {
                    // Always set the newest values (and last modified date).
                    const previousWaitingTime = this.waitingTime;
                    this.waitingTime = waitingTime;

                    // Test if waiting time or custom waiting time is changed.
                    if (!previousWaitingTime || previousWaitingTime.time !== waitingTime.time || previousWaitingTime.timeCustom !== waitingTime.timeCustom) {
                        this.loadWaitingTimeLogging();
                    }
                });
            return socket;
        },

        /**
         * Load waiting time.
         */
        loadWaitingTime() {
            axios.get('/api/waiting')
                .then(response => {
                    this.errorMessage = null;
                    this.waitingTime = response.data;
                })
                .catch(error => {
                    const responseStatus = error.response.status;
                    // Unauthorized, redirect to login screen.
                    if (responseStatus === 401) {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Load logging data.
         */
        loadWaitingTimeLogging() {
            axios.get('/api/logging')
                .then(response => {
                    this.errorMessage = null;
                    this.waitingTimeLogging = response.data;
                })
                .catch(error => {
                    const responseStatus = error.response.status;
                    // Unauthorized, redirect to login screen.
                    if (responseStatus === 401) {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        startEditingWaitingTime() {
            this.editWaitingTime = true;
            if (this.waitingTime && this.waitingTime.timeCustom) {
                this.customWaitingTime = `${this.waitingTime.timeCustom}`;
            }
            // Focus when visible (give some time to render).
            setTimeout(() => {
                const inputElm = this.$el.querySelector('input');
                if (inputElm) {
                    inputElm.focus();
                }
            }, 0);
        },

        cancelEditingWaitingTime() {
            this.editWaitingTime = false;
        },

        /**
         * Save new custom waiting time.
         */
        submit() {
            const newWaitingTime = parseInt(this.customWaitingTime.trim() || '0', 10);
            OrderServices.setCustomWaitingTime(newWaitingTime)
                .then(() => {
                    this.errorMessage = null;
                    // Update local custom waiting time immediately.
                    if (this.waitingTime) {
                        this.waitingTime.timeCustom = newWaitingTime;
                    }
                    this.editWaitingTime = false;
                })
                .catch(error => {
                    const responseStatus = error.response.status;
                    // Unauthorized, redirect to login screen.
                    if (responseStatus === 401) {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Go back one page back in history (back to the orders overview)
         */
        back() {
            this.$router.back();
        },

        /**
         * Listen for browser tab visibility changes.
         */
        installVisibilityChangeListener() {
            document.addEventListener('visibilitychange', this.visibilityChangeListener);
        },

        removeVisibilityChangeListener() {
            document.removeEventListener('visibilitychange', this.visibilityChangeListener);
        },

        /**
         * Disable websocket messages in case the tab is invisible (save resources).
         */
        visibilityChangeListener() {
            if (document.visibilityState === 'visible') {
                this.socket.open();
                this.loadWaitingTime();
                this.loadWaitingTimeLogging();
            } else {
                this.socket.close();
            }
        },

        // Filters.
        dateFormat: DateFormatFilter.dateFormat

    },
    created() {
        this.darkMode = UtilityServices.isDarkMode();
        this.loadWaitingTime();
        this.loadWaitingTimeLogging();
    },
    mounted() {
        this.socket = this.createSocket();
        this.installVisibilityChangeListener();
    },

    beforeUnmount() {
        if (this.socket) {
            this.socket.destroy();
        }
        this.removeVisibilityChangeListener();
    }
});

