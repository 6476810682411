// eslint-disable-next-line
import {AxiosError} from 'axios'; // jshint ignore:line

export default {

    /**
     * Extract readable error from Axios error response.
     *
     * @param {AxiosError|IResponseError|Error|null|undefined} errorResponse Axios error response
     * @param vueInstance Vue instance for i18n messages
     * @return {string} readable error message
     */
    extractErrorMessage(errorResponse: AxiosError | Error | any | null | undefined, vueInstance: any):string {
        if (!vueInstance || !vueInstance.$t) {
            throw Error('Invalid or no Vue instance');
        }
        if (!errorResponse) {
            return '';
        }
        let errorMessage = '';
        if (errorResponse.response) {
            if (errorResponse.response.status === 401) {
                // noinspection SpellCheckingInspection,JSUnresolvedFunction
                errorMessage = vueInstance.$t('loginform.messages.error.invalidusernameorpassword');
            } else if (errorResponse.response.data.errorMessage || errorResponse.response.data.errorDetails) {
                errorMessage = errorResponse.response.data.errorMessage || errorResponse.response.data.errorDetails;
            } else {
                errorMessage = JSON.stringify(errorResponse.response.data);
            }
        } else {
            errorMessage = errorResponse.message || '' + errorResponse;
        }
        return errorMessage;
    },

    /**
     * Parse the query parameters.
     * https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
     *
     * @param url Search query (should start with ?)
     * @return {{}} key value map
     */
    parseQueryParameters(url: string): any {
        const query = url ? url.substr(1) : '';
        const result = {};
        query.split('&').forEach(function (part) {
            const item = part.split('=');
            if (item.length >= 2) {
                // @ts-ignore
                result[item[0]] = decodeURIComponent(item[1]);
            }
        });
        return result;
    }
};


