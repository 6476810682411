export default {

    /**
     * Remove spaces from postal code and convert lower case to upper case.
     *
     * @param {string|undefined} text Postalcode
     * @return {string} Capitalized text or empty string
     */
    postalcode: (text:string|undefined) => {
        if (text && text.replace) {
            return text.replace(/ +/g, '').toLocaleUpperCase();
        }
        return '';
    }
};
