// noinspection DuplicatedCode

export enum PaymentType {
    IDEAL = 'iDeal',
    CASH = 'contant',
    CREDITCARD = 'creditcard',
    /* Custom type PIN. */
    PIN = 'PIN',
    /* Custom type Betaalverzoek. */
    PAYMENTREQUEST = 'paymentrequest'
}
// UC = Upper Case variant, used as start of a sentence.
export const PAYMENT_TYPE_LABELS_UC: { [key: string]: string } = {
    [PaymentType.IDEAL]: 'iDeal',
    [PaymentType.PIN]: 'PIN',
    [PaymentType.CREDITCARD]: 'Creditcard',
    [PaymentType.PAYMENTREQUEST]: 'Betaalverzoek',
    [PaymentType.CASH]: 'Contant'
};
export const PAYMENT_TYPE_LABELS: { [key: string]: string } = {
    [PaymentType.IDEAL]: 'iDeal',
    [PaymentType.PIN]: 'PIN',
    [PaymentType.CREDITCARD]: 'creditcard',
    [PaymentType.PAYMENTREQUEST]: 'betaalverzoek',
    [PaymentType.CASH]: 'contant'
};

// Test if all the enums still have a label.
(() => {
    for (const key of Object.values(PaymentType)) {
        if (!PAYMENT_TYPE_LABELS_UC[key]) {
            throw new Error(`Found PaymentType without label: '${key}'`);
        }
    }
})();

