import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d4a0be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-1 px-1" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_3 = {
  key: 1,
  class: "spinner-container ml-1"
}
const _hoisted_4 = { class: "mt-n3" }
const _hoisted_5 = {
  class: "d-inline-block",
  style: {"min-width":"8em"}
}
const _hoisted_6 = {
  key: 0,
  class: "text-monospace",
  id: "orders-lastmodified-time"
}
const _hoisted_7 = { key: 'revenue' }
const _hoisted_8 = { colspan: "100%" }
const _hoisted_9 = {
  id: "table-header",
  class: "d-flex justify-content-between"
}
const _hoisted_10 = { id: "orders-count" }
const _hoisted_11 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_12 = { id: "orders-revenue" }
const _hoisted_13 = { class: "text-right" }
const _hoisted_14 = { href: "/#/orders" }
const _hoisted_15 = {
  key: 1,
  name: "orders",
  "vue:is": "transition-group"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "text-monospace" }
const _hoisted_19 = { class: "d-none d-sm-table-cell" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "d-inline-block d-sm-none" }
const _hoisted_24 = { key: 'numberoforders' }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_delivery_distance = _resolveComponent("delivery-distance")!
  const _component_price_and_payment = _resolveComponent("price-and-payment")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    (_ctx.progress)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(_component_spinner)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("p", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('orders.labels.lastchange')) + ":", 1),
      (_ctx.lastChange)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.dateFormat(_ctx.lastChange, _ctx.$t('orders.labels.lastchangetimeformat'), _ctx.$i18n.locale)), 1))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.lastChange]
    ]),
    _createElementVNode("table", {
      class: _normalizeClass(["table table-sm table-striped orders", {'table-dark':_ctx.darkMode}])
    }, [
      (_ctx.orders)
        ? (_openBlock(), _createElementBlock("thead", {
            key: 0,
            class: _normalizeClass({'thead-light':!_ctx.darkMode, 'thead-dark':_ctx.darkMode})
          }, [
            (_openBlock(), _createElementBlock("tr", _hoisted_7, [
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, [
                      _createTextVNode("# "),
                      _createElementVNode("strong", null, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.orders.length), 1)
                      ])
                    ]),
                    (_ctx.totalRevenue)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                          _createTextVNode("€ "),
                          _createElementVNode("span", _hoisted_12, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.price(_ctx.totalRevenue, _ctx.$i18n.locale)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t('orders.labels.orders')), 1),
                    _createTextVNode("   ")
                  ])
                ])
              ])
            ]))
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.orders)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "bg-warning",
                key: order.Bestelnummer,
                onClick: ($event: any) => (_ctx.showDetails(order))
              }, [
                _createElementVNode("td", null, [
                  (order.channel!=='CUSTOM')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(order.channel), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.getDeliveryDateAndTime(order)), 1),
                _createElementVNode("td", _hoisted_19, [
                  _createVNode(_component_delivery_distance, {
                    "geo-location-restaurant": _ctx.geoLocationRestaurant,
                    order: order
                  }, null, 8, ["geo-location-restaurant", "order"])
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_price_and_payment, { order: order }, null, 8, ["order"])
                ]),
                _createElementVNode("td", null, [
                  _createTextVNode(_toDisplayString(order.Naam), 1),
                  (order.Nieuwe_Klant === 'Ja')
                    ? (_openBlock(), _createElementBlock("sup", _hoisted_20, "1"))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", null, [
                  (order.typeOfDelivery === 'delivery')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                        _createTextVNode(_toDisplayString(_ctx.ucfirst(_ctx.hideEmpty(order.Plaats))) + _toDisplayString(order.Plaats && order.Plaats.length ? ', ' : '') + _toDisplayString(_ctx.hideEmpty(order.Adres)) + " ", 1),
                        (!order.Plaats && !order.Adres)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_22, "(" + _toDisplayString(_ctx.$t('orders.messages.noaddress')) + ")", 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_23, [
                          _createVNode(_component_delivery_distance, {
                            "geo-location-restaurant": _ctx.geoLocationRestaurant,
                            order: order
                          }, null, 8, ["geo-location-restaurant", "order"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_16))
            }), 128)),
            (_ctx.orders && !_ctx.orders.length)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_24, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('orders.messages.noorders')), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}