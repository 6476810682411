import {reactive, watch} from "vue";
import {ICustomer} from "@/interfaces/customer";

const LOCALSTORAGE_KEY_CUSTOMER = 'customer';

const initialCustomer: ICustomer = {};

// Load from localStorage.
if (window.localStorage) {
    try {
        const customerString = window.localStorage.getItem(LOCALSTORAGE_KEY_CUSTOMER);
        if (customerString) {
            const customerFromStorage = JSON.parse(customerString);
            for (const p in customerFromStorage) {
                // @ts-ignore
                initialCustomer[p] = customerFromStorage[p];
            }
        }
    } catch (e) {
        // Ignore it, just start clean.
    }
}

/**
 * Internal cart store.
 */
export const customerStore: ICustomer = reactive<ICustomer>(initialCustomer);

/**
 * Watch changes and store it in local storage.
 */
watch(customerStore, () => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.setItem(LOCALSTORAGE_KEY_CUSTOMER, JSON.stringify(customerStore));
});
