export default {
    /**
     * Validator to validate NL phone number.
     *
     * @param modelValue Model value
     * @returns true if valid, false otherwise
     */
    phoneValidator(modelValue?: string|null): boolean {

        // "Required" is handled by the required validator.
        if (!modelValue) {
            return true;
        }
        modelValue = modelValue.replace(/\D/gi, '');
        // 06-numbers should contain exactly 10 digits.
        // noinspection RedundantIfStatementJS
        if (modelValue.slice(0, 2) === '06' && modelValue.length !== 10) {
            return false;
        }
        // noinspection RedundantIfStatementJS
        if (modelValue.slice(0, 3) === '316' && modelValue.length !== 11) {
            return false;
        }
        return true;
    },

    /**
     * Validator to test for NL postcode.
     *
     * @param modelValue Model value
     * @returns true if valid, false otherwise
     */
    postcodeValidator(modelValue?: string|null): boolean {
        // "Required" is handled by the required validator.
        if (!modelValue) {
            return true;
        }
        return /^[0-9]{4} *[a-z]{2}$/gi.test(modelValue.trim())
    },

    /**
     * Validator to test email format.
     *
     * @param modelValue Model value
     * @returns true if valid, false otherwise
     */
    emailValidator(modelValue?: string|null): boolean {
        // "Required" is handled by the required validator.
        if (!modelValue) {
            return true;
        }
        // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(modelValue.trim());
    }

}
