
import {defineComponent} from 'vue';
import moment from 'moment';
import NetworkServices from '../services/NetworkServices';
import UtilityServices from '../services/UtilityServices';
import Spinner from '../components/Spinner.vue';
import OrderServices from '../services/OrderServices';
import DateFormatFilter from '../components/DateFormatFilter';
import UCFirstFilter from '../components/UCFirstFilter';
import CeilFilter from '../components/CeilFilter';
import DeliveryDistance from '@/components/DeliveryDistance.vue';
import PriceAndPayment from "@/components/PriceAndPayment.vue";
import {IOrder} from '@/interfaces/order';
import {PositionAsDecimal} from '@/interfaces/geo';
import PriceFilter from "@/components/PriceFilter";

export default defineComponent({

    components: {
        DeliveryDistance,
        PriceAndPayment,
        Spinner
    },
    props: {},
    data: function () {
        return {
            /** @type {IOrder[]|undefined} */
            orders: undefined as IOrder[]|undefined,
            /** @type {PositionAsDecimal|undefined} */
            geoLocationRestaurant: undefined as PositionAsDecimal|undefined,
            progress: false,
            /** @type {string|null} */
            errorMessage: null as string|null,
            /**
             * @type {string|null} DateTime as string in format 2020-02-17T22:36:54.926Z (serialized Date object)
             */
            lastChange: null as string|null
        };
    },
    methods: {

        /**
         * Return complete delivery time.
         *
         * @param {IOrder} order Order
         * @returns {string} Time of delivery to show
         */
        getDeliveryDateAndTime(order: IOrder): string {
            return order && order.deliveryDate ? moment(order.deliveryDate).format('dddd D MMMM HH:mm') : '-';
        },

        /**
         * Get a fresh list of all orders.
         *
         * @param {function} [callback] Called when loading done (optional)
         */
        loadOrders(callback?: () => void): void {
            this.progress = true;
            OrderServices.loadOrders(true /* pre-orders */)
                .then((data: any) => {
                    // Reset error message.
                    this.errorMessage = null;
                    this.orders = data.orders;
                    this.lastChange = data.lastModified;
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    // Unauthorized, redirect to login screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                    if (callback) {
                        callback();
                    }
                })
                .finally(() => {
                    this.progress = false;
                })
        },

        /**
         * Location of restaurant.
         */
        loadGeoLocationRestaurant(): void {
            OrderServices.loadGeoLocationRestaurant()
                .then(geoLocationRestaurant => this.geoLocationRestaurant = geoLocationRestaurant)
                .catch(error => {
                    // Unauthorized, redirect to the login screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Open details on order row click.
         *
         * @param {IOrder} order the order to show details from
         */
        showDetails(order: IOrder): void {
            UtilityServices.saveCurrentScrollPosition();
            this.$router.push(`/preorder/${order.id}`);
        },

        // Previously filters.
        price: PriceFilter.price,
        hideEmpty: UtilityServices.hideEmpty,
        dateFormat: DateFormatFilter.dateFormat,
        ucfirst: UCFirstFilter.ucfirst,
        ceil: CeilFilter.ceil

    },

    computed: {
        /**
         * Calculate the total revenue.
         *
         * @return {number} The total revenue as float
         */
        totalRevenue(): number {
            return (this.orders || [] as IOrder[]).reduce((acc: number, order: IOrder) => {
                const totaalbedrag = (order.Totaalbedrag || '0').replace(',', '.');
                return (acc || 0) + (Number.parseFloat(totaalbedrag) || 0);
            }, 0);
        },

        darkMode(): boolean {
            return UtilityServices.isDarkMode()
        }
    },

    created() {
        moment.locale(this.$i18n.locale);
        this.loadOrders(() => {
            UtilityServices.resetCurrentScrollPosition();
            this.loadGeoLocationRestaurant();
        });
    },
});

