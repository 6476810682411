import {createI18n} from 'vue-i18n';
import {App, createApp} from 'vue';
import {createRouter, createWebHashHistory} from 'vue-router';
import jQuery from 'jquery';
import messages from './messages';
import Login from './pages/Login.vue';
import Order from './pages/Order.vue';
import Orders from './pages/Orders.vue';
import PreOrders from './pages/PreOrders.vue';
import Logging from './pages/Logging.vue';
import Totals from './pages/Totals.vue';
import OrderPage from "@/pages/OrderPage.vue";
import Checkout from "@/pages/Checkout.vue";
import AppCashDesk from './App.vue';
import AppOrderWebsite from './App2.vue';
import Finish from '@/pages/Finish.vue';
import CartPage from '@/pages/CartPage.vue';
import logServices from '@/services/LogServices';

// Webpack will add the bootstrap css to the bundle.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';


// @ts-ignore
global.jQuery = jQuery;
// @ts-ignore
global.$ = jQuery;


// Determine based on URL what application to show.
const kassa = /^(kassa\.|127\.0\.0\.1|192\.)/gi.test(window.location.host);

// Show the orders.
if (!kassa) {

    // VueRouter.
    // https://router.vuejs.org/guide/essentials/passing-props.html#boolean-mode
    const router = createRouter({
        history: createWebHashHistory(),
        routes: [
            {path: '/order-page', component: OrderPage},
            {path: '/checkout', component: Checkout},
            {path: '/cart', component: CartPage},
            {path: '/finish/:id', component: Finish, props: true},
            {path: '/:dummy(.*)', redirect: '/order-page'}
        ]
    });

    const app = createApp(AppOrderWebsite);

    installErrorHandler(app);

    app.use(router);
    app.mount('#app');


} else {

    const LOCALE_EN_US = 'en-US';
    const LOCALE_NL_NL = 'nl-NL';

    // VueRouter.
    // https://router.vuejs.org/guide/essentials/passing-props.html#boolean-mode
    const router = createRouter({
        history: createWebHashHistory(),
        routes: [
            {path: '/login', component: Login},
            {path: '/orders', component: Orders},
            {path: '/order/:id', component: Order, props: true},
            {path: '/preorder/:id', component: Order, props: true},
            {path: '/logging', component: Logging},
            {path: '/totals', component: Totals},
            {path: '/preorders', component: PreOrders},
            {path: '/:dummy(.*)', redirect: '/orders'}
        ]
    });

    // For now, we only support English and Dutch.
    const preferredLocale = getFirstBrowserLanguage();
    const locale = preferredLocale && preferredLocale.indexOf('en') === 0 ? LOCALE_EN_US : LOCALE_NL_NL;

    // Create VueI18n instance with options.
    // http://kazupon.github.io/vue-i18n/started.html#javascript
    const i18n = createI18n({
        locale,
        fallbackLocale: LOCALE_EN_US,
        messages
    });

    const app = createApp(AppCashDesk);

    installErrorHandler(app);

    app.use(router);
    app.use(i18n);
    app.mount('#app');
}

/**
 * Get the browsers preferred language.
 * https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
 *
 * @return {string|null|*}
 */
function getFirstBrowserLanguage() {

    // support for HTML 5.1 "navigator.languages"
    const nav = window.navigator;
    if (Array.isArray(nav.languages)) {
        let shortLanguage = null;
        for (let i = 0; i < nav.languages.length; i++) {
            const language = nav.languages[i];
            const len = language.length;
            if (!shortLanguage && len) {
                shortLanguage = language;
            }
            if (language && len > 2) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    let shortLanguage = null;
    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
        const language = (nav as any)[browserLanguagePropertyKeys[i]];
        //skip this loop iteration if property is null/undefined.  IE11 fix.
        if (language == null) {
            continue;
        }
        const len = language.length;
        if (!shortLanguage && len) {
            shortLanguage = language;
        }
        if (language && len > 2) {
            return language;
        }
    }

    return shortLanguage;
}

/**
 * By default, the Error object is not serializable.
 * We make it serializable:
 * https://stackoverflow.com/questions/24998413/nodejs-json-stringify-error-object
 */
function configureJSON() {
    Object.defineProperty(Error.prototype, 'toJSON', {
        configurable: true,
        value: function () {
            const alt: any = {};
            const storeKey = function (key: any) {
                // @ts-ignore
                alt[key] = this[key];
            };
            Object.getOwnPropertyNames(this)
                .forEach(storeKey, this);
            return alt;
        }
    });
}

function installErrorHandler(app: App) {

    // Make Error object serializable, so we can send it to the server.
    configureJSON();

    // App-level error handler that captures errors from all descendant components.
    app.config.errorHandler = (error: unknown) => {
        const errorObject = error as {stack: string, message: string};
        logServices.error('Vue error handler: ' + errorObject.message, errorObject);
    }
    app.config.warnHandler = (errorMessage) => {
        logServices.warn('Vue warn handler: ' + errorMessage);
    }
}
