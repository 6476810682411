
import {defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router'
import Spinner from '../components/Spinner.vue';
import {IOrder} from '../../../app/interfaces/order';
import OrderServices from '@/services/OrderServices';
import UtilityServices from '@/services/UtilityServices';
import DateServices from '@/services/DateServices';
import {OrderLabel} from '../../../app/enums/order-label';
import PriceFilter from '@/components/PriceFilter';
import DateFormatFilter from '@/components/DateFormatFilter';
import UCFirstFilter from '@/components/UCFirstFilter';
import {IGiftCard} from "@/interfaces/gift-card";
import {IResponseError} from "@/interfaces/error";
import logServices from "@/services/LogServices";

export default defineComponent({

    components: {
        Spinner
    },

    setup() {
        const router = useRouter()
        UtilityServices.setScrollToTop();

        // Start with today.
        const now = DateServices.getCurrentDate();
        const selectedDate = ref<string>(DateServices.getDatestamp(now));

        // Create date range to select date.
        const dateRange = Object.keys((new Array(30)).fill(1))
            .map(i => Number.parseInt(i, 10))
            .map(i => {
                const tmpDate = new Date(now.getTime());
                tmpDate.setDate(tmpDate.getDate() - i);
                return DateServices.getDatestamp(tmpDate);
            });

        // If null, that means the orders are loading.
        const ordersByType = ref<{type: string, orders: IOrder[]}[]|null>([]);
        const giftCards = ref<IGiftCard[]>([]);

        const errorMessage = ref<string|null>(null);
        const darkMode = UtilityServices.isDarkMode();

        /**
         * Load all orders.
         *
         * @param date The date to show orders for
         */
        const loadOrders = (date: Date): void => {

            if (!date || !date.getDay) {
                throw Error('Date is a required parameter.');
            }

            // Null means the orders are loading.
            ordersByType.value = null;
            errorMessage.value = null;
            OrderServices.loadOrdersByPaymentType(date)
                .then(orders => {
                    ordersByType.value = orders as ({type: string, orders: IOrder[]}[]);
                })
                .catch(error => {
                    // Unauthorized, redirect to log-in screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        router.push('/login');
                        return;
                    }
                    ordersByType.value = [];
                    errorMessage.value = error.errorMessage;
                });
        };

        /**
         * Load gift cards to show new and deleted gift cards.
         *
         * @param date The date to show giftcards for
         */
        const loadGiftCards = (date: Date) => {

            if (!date || !date.getDay) {
                throw Error('Date is a required parameter.');
            }

            giftCards.value = [];
            OrderServices.loadGiftCards(date)
                .then((data: IGiftCard[]) => {
                    const lGiftCards = (data || []);
                    OrderServices.sortGifCards(lGiftCards);
                    lGiftCards.reverse();
                    giftCards.value = lGiftCards;
                })
                .catch(error => {
                    const message = (error as IResponseError).errorMessage || (error as Error).message || JSON.stringify(error);
                    logServices.error('Totals: error loading gift cards: ' + message, error);
                });
        }

        /**
         * Sort the given orders.
         * (given array will be sorted).
         *
         * @param orders The orders
         */
        const orderByDeliveryDate = (orders: IOrder[]): IOrder[] => {
            orders.sort((a, b) => {
                const date1 = a.deliveryDate || 0;
                const date2 = b.deliveryDate || 0;

                if (date1 < date2) {
                    return -1;
                }
                if (date1 > date2) {
                    return 1;
                }
                return 0;
            });
            return orders;
        };

        /**
         * Delete given order from memory.
         *
         * @param bestelnummer Order bestelnummer
         */
        const deleteOrder = (bestelnummer: string) => {
            if (!bestelnummer) {
                throw Error('Cannot delete order without "Bestelnummer"');
            }
            if (!ordersByType.value) {
                throw Error('Cannot delete order, no orders loaded yet');
            }
            ordersByType.value.forEach((ordersByTypeValue: {type: string, orders: IOrder[]}) => {
                const orders = ordersByTypeValue.orders;
                const tmpOrder = orders.find(order => order.Bestelnummer === bestelnummer);
                if (tmpOrder) {
                    orders.splice(orders.indexOf(tmpOrder), 1);
                }
            });
        }

        /**
         * Calculate total price.
         *
         * @param  orders List of orders
         * @return  Total price in cents
         */
        const getTotalPrice = (orders: IOrder[]): number => {
            if (!orders || !orders.length) {
                return 0;
            }
            // Convert all prices to cents before calculating the sum.
            return orders.reduce((sum, order) => {
                return sum + parseInt(order.Totaalbedrag.replace('.', ''), 10);
            }, 0);
        };

        /**
         * Calculate total price of only the pre-orders.
         *
         * @param  orders List of orders
         * @return  Total price in cents
         */
        const getTotalPreOrderPrice = (orders: IOrder[]): number => {
            if (!orders || !orders.length) {
                return 0;
            }
            // Convert all prices to cents before calculating the sum.
            return orders
                .filter(order => order.labels && order.labels.indexOf(OrderLabel.PREORDER) !== -1)
                .reduce((sum, order) => sum + parseInt(order.Totaalbedrag.replace('.', ''), 10), 0);
        };

        /**
         * Calculate total price of only the pre-paid orders (which show as 0.00).
         *
         * @param  orders List of orders
         * @return  Total price in cents
         */
        const getTotalPrePaidPrice = (orders: IOrder[]): number => {
            if (!orders || !orders.length) {
                return 0;
            }
            // Convert all prices to cents before calculating the sum.
            return orders
                .filter(order => order.labels && order.labels.indexOf(OrderLabel.PREORDERED) !== -1)
                .reduce((sum, order) => {
                    const totalPrice = parseInt(order.Totaalbedrag.replace('.', ''), 10);
                    const totalPrepaidPrice = parseInt((order.original?.Totaalbedrag || '0.00').replace('.', ''), 10);
                    return totalPrice === 0 ? sum + totalPrepaidPrice : sum;
                }, 0);
        };

        /**
         * What to display for the name and address field.
         *
         * @param  order Order object
         * @return  Name and address or name only
         */
        const formatOrderNameAndAddress = (order: IOrder): string => {
            let result: string = UCFirstFilter.ucfirst(`${order.Naam || ''} ${order.Bedrijfsnaam || ''}`.trim());
            // Add address and city also?
            if (order.Adres) {
                result += `, ${UCFirstFilter.ucfirst(order.Adres)}`;
                result = result.trim();
                if (order.Plaats) {
                    result += ` ${UCFirstFilter.ucfirst(order.Plaats)}`;
                    result = result.trim();
                }
            }
            return result;
        };

        /**
         * Determine if this order is a pre-order.
         *
         * @param order The order
         * @return True if pre-order
         */
        const isPreOrder = (order: IOrder): boolean => {
            return (order.labels && order.labels.indexOf(OrderLabel.PREORDER) !== -1);
        };

        /**
         * Print the current page.
         */
        const print = () => {
            try {
                // Printing in Safari.
                // https://stackoverflow.com/questions/31171099/window-print-does-not-work-in-safari
                // noinspection JSDeprecatedSymbols
                if(!document.execCommand || !document.execCommand('print')) {
                    window.print();
                }
            } catch (e) {
                window.print();
            }
        };

        /**
         * Selected date is changed, update the list of orders.
         */
        const dateUpdated = () => {
            const newDate = DateServices.parseDatestamp(selectedDate.value);
            loadOrders(newDate);
            loadGiftCards(newDate);
        }

        loadOrders(now);
        loadGiftCards(now);

        return {
            selectedDate,
            dateRange,

            ordersByType,
            giftCards,
            errorMessage,
            darkMode,

            priceFilter: PriceFilter.price,
            dateFilter: DateFormatFilter.dateFormat,
            orderByDeliveryDate,
            deleteOrder,
            formatOrderNameAndAddress,
            isPreOrder,
            getTotalPrePaidPrice,
            getTotalPreOrderPrice,
            getTotalPrice,
            print,
            dateUpdated
        };
    }

});

