import {IResponseError} from "@/interfaces/error";
import {ILogRecord} from "@/interfaces/log-record";

export default {

    info(errorMessage: string): void {
        this.log('info', errorMessage);
    },

    warn(errorMessage: string): void {
        this.log('warn', errorMessage);
    },

    error(errorMessage: string, error?: {stack: string, message: string}): void {
        this.log('warn', errorMessage, error);
    },

    log(errorLevel: 'error'|'warn'|'info', errorMessage: string, error?: {stack: string, message: string}): void {
        if (errorLevel === 'info') {
            console.log(errorMessage);
            return;
        }
        this.postError(errorLevel, errorMessage, error)
            .catch(error => {
                // Error calling the API.
                console.error(`Error POSTing ${errorLevel}: ${JSON.stringify(error)}`);
            });
    },

    /**
     * Post new error to backend API.
     *
     * @return Promise that resolves on success or rejects with error
     */
    postError(errorLevel: "error"|"warn", errorMessage: string, error?: {stack: string, message: string}): Promise<void|IResponseError|Error> {
        return new Promise((resolve, reject) => {

            const logRecord:ILogRecord = {
                errorLevel,
                errorMessage,
                error
            };

            fetch('/api/log', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify(logRecord)
            })
                .then((response: Response) => {
                    if (response.status !== 200) {
                        reject({
                            status: response.status,
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    resolve();
                })
                .catch(reject);
        });
    },
};


