<template>
    <PageHeader/>
    <router-view/>
    <PageFooter/>
</template>

<script setup type="ts">

import {onMounted} from "vue";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

onMounted(() => {
    /* Override dark theme. */
    document.body.setAttribute('style', 'background-color:white !important; color:black !important')

    // This doesn't work with "!important".
    // https://stackoverflow.com/questions/29873882/how-can-use-javascript-to-set-a-style-with-important
    //document.body.style.backgroundColor = '#fcfcfc !important';
    //document.body.style.color = 'black !important';
});

</script>
