
import {defineComponent} from 'vue';
import axios from 'axios';
import Spinner from '../components/Spinner.vue';
import NetworkServices from '../services/NetworkServices';

const STORAGE_KEY_USERNAME = 'username';
const storage = window.localStorage;

export default defineComponent({
    components: {
        Spinner
    },
    props: {},
    data: function () {
        let username = '';
        if (storage && storage.getItem(STORAGE_KEY_USERNAME)) {
            username = storage.getItem(STORAGE_KEY_USERNAME) || '' as string;
        }
        return {
            username:username as string,
            password: '',
            progress: false,
            errorMessage: ''
        };
    },
    methods: {

        /**
         * Submit the login form.
         */
        onSubmit() {
            if (!this.username || !this.password) {
                this.errorMessage = this.$t('loginform.messages.error.usernamepasswordrequired');
                return;
            }
            if (storage) {
                storage.setItem(STORAGE_KEY_USERNAME, this.username);
            }
            this.progress = true;

            // Send POST form urlencoded:
            // https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
            const config = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
            const postData = new URLSearchParams();
            postData.append('username', this.username);
            postData.append('password', this.password);
            axios.post('/api/login', `${postData}`, config)
                .then(() => {
                    this.errorMessage = '';
                    this.progress = false;
                    // Redirect to the orders.
                    this.$router.push('/orders')
                })
                .catch(error => {
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                    this.progress = false;
                });
        }
    },
    mounted() {
        // Focus username or password field.
        const usernameElm = this.$el.querySelector('#username');
        const passwordElm = this.$el.querySelector('#password');
        if (!this.username) {
            usernameElm.focus();
        } else {
            passwordElm.focus();
        }

        // If username and password provided via URL, start immediate login attempt.
        const queryParams = document.location.search;
        if (queryParams) {
            const result = NetworkServices.parseQueryParameters(queryParams);
            if (result.username && result.password) {
                this.username = result.username;
                this.password = result.password;
                this.onSubmit();
            }
        }
    }
});
