
import NetworkServices from '../services/NetworkServices';
import PriceFilter from '../components/PriceFilter';
import UCFirstFilter from '../components/UCFirstFilter';
import DateFormatFilter from '../components/DateFormatFilter';
import UtilityServices from '../services/UtilityServices';
import moment from 'moment';
import io, {Socket} from 'socket.io-client';
import PostalcodeFilter from '../components/PostalcodeFilter';
import Spinner from '../components/Spinner.vue';
import OrderServices from '../services/OrderServices';
import Dishes from '../components/Dishes.vue';
import SentMessages from '../components/SentMessages.vue';
import DeliveryDistance from '@/components/DeliveryDistance.vue';
import {defineComponent, PropType} from 'vue';
import {IOrder} from '@/interfaces/order';
import {PositionAsDecimal} from '@/interfaces/geo';
import {OrderStatus} from "@/enums/order-status";

// noinspection SpellCheckingInspection
export default defineComponent({
    components: {
        DeliveryDistance,
        SentMessages,
        Dishes,
        Spinner
    },
    props: {
        /**
         * Order id. ($route param :id is passed in as prop)
         * https://router.vuejs.org/guide/essentials/passing-props.html#passing-props-to-route-components
         */
        id: {
            type: String as PropType<string>,
            required: true
        }
    },

    data: function () {
        return {
            order: null as IOrder|null,
            /** @type {PositionAsDecimal|undefined} */
            geoLocationRestaurant: undefined as PositionAsDecimal|undefined,
            progress: false,
            defaultVatTariff: undefined as number|undefined,
            /**
             * @type {string|null} DateTime as string in format 2020-02-17T22:36:54.926Z (serialized Date object)
             */
            lastChange: null as string|null,
            errorMessage: null as string|null,
            infoMessage: null as string|null,
            socket: null as typeof Socket|null,
            isPreOrder: false,
            showJson: false
        };
    },
    methods: {

        /**
         * Initialize client socket and start listening for change messages.
         * @return {Socket}
         */
        createSocket(): typeof Socket {
            const socket = io();
            socket.on('orders', (data: any) => {
                // We receive a notification for all orders, filter out only the current order.
                const order = data.orders.find((order: any) => this.order && this.order.Bestelnummer === order.Bestelnummer);
                if (this.order && !UtilityServices.deepEquals(this.order, order)) {
                    this.order = order;
                    this.lastChange = data.lastModified;
                }
            });
            return socket;
        },

        /**
         * Load order with ${this.id} id.
         */
        loadOrder(): void {
            this.progress = true;
            this.infoMessage = null;

            OrderServices.loadOrder(this.id)
                .then(data => {
                    const {order, lastModified} = (data as {order: IOrder, lastModified: Date});
                    this.errorMessage = null;

                    // Only show update in case of change.
                    this.lastChange = `${lastModified}`;
                    if (!UtilityServices.deepEquals(this.order, order)) {
                        this.order = order;
                    }
                })
                .catch((error: {errorCode: string, errorMessage: string}) => {
                    // Unauthorized, redirect to log-in screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    // Not found (anymore).
                    if (error.errorCode === 'NOT_FOUND') {
                        this.infoMessage = this.$t('order.messages.orderdoesnotexist');
                        return;
                    }

                    // Try to load the cached order.
                    OrderServices.loadOrder(this.id, true)
                        .then(data => {
                            const {order, lastModified} = (data as {order: IOrder, lastModified: Date});
                            this.infoMessage = this.$t('order.messages.staleorder');
                            this.lastChange = `${lastModified}`;
                            this.order = order;
                        })
                        .catch((error: {errorCode: string, errorMessage: string}) => {
                            this.errorMessage = `${error.errorCode || ''} ${error.errorMessage || error}`;
                        });
                })
                .finally(() => {
                    this.progress = false;
                });
        },

        /**
         * Load order with ${this.id} id.
         */
        loadPreOrder(): void {
            this.progress = true;
            OrderServices.loadOrders(true /* pre-orders */)
                .then((data: any) => {
                    // Reset error message.
                    this.errorMessage = null;
                    this.lastChange = data.lastModified;
                    const order = data.orders.find((order: IOrder) => order.id === this.id);
                    if (!order) {
                        this.infoMessage = this.$t('order.messages.orderdoesnotexist');
                        return;
                    }
                    this.order = order;
                })
                .catch(error => {
                    // Unauthorized, redirect to log-in screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    // Not found (anymore).
                    if (error.response.status === 404) {
                        this.infoMessage = this.$t('order.messages.orderdoesnotexist');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                })
                .finally(() => {
                    this.progress = false;
                })
        },

        /**
         * Location of restaurant.
         */
        loadGeoLocationRestaurant(): void {
            OrderServices.loadGeoLocationRestaurant()
                .then(geoLocationRestaurant => this.geoLocationRestaurant = geoLocationRestaurant)
                .catch(error => {
                    // Unauthorized, redirect to log-in screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Load the VAT low tariff from the config.
         */
        loadDefaultVatTariff(): void {
            OrderServices.loadVatTariffs()
                .then(config => {
                    // Convert to number (in the past this could have been a string).
                    // (we keep it zero, so it is obvious it's not configured yet).
                    this.defaultVatTariff = config.taxLow ? +config.taxLow : 0;
                })
                .catch(error => {
                    this.errorMessage = 'Error reading configuration: ' + NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Go back one page back in history (back to the orders overview)
         */
        back(): void {
            this.$router.back();
        },

        /**
         * Listen for browser tab visibility changes.
         */
        installVisibilityChangeListener() {
            document.addEventListener('visibilitychange', this.visibilityChangeListener);
        },

        removeVisibilityChangeListener() {
            document.removeEventListener('visibilitychange', this.visibilityChangeListener);
        },

        /**
         * Disable websocket messages in case the tab is invisible (save resources).
         */
        visibilityChangeListener(): void {
            if (!this.socket) {
                return;
            }
            if (document.visibilityState === 'visible') {
                this.socket.open();
                this.loadOrder();
            } else {
                this.socket.close();
            }
        },

        isSitedishOrder(): boolean {
            return OrderServices.isSitedishOrder(this.order);
        },

        // Previously filters.
        price: PriceFilter.price,
        ucfirst: UCFirstFilter.ucfirst,
        dateFormat: DateFormatFilter.dateFormat,
        hideEmpty: UtilityServices.hideEmpty,
        postalcode: PostalcodeFilter.postalcode

    },
    computed: {

        /**
         * @return The formatted price; e.g. 12,95.
         */
        orderPrice(): string {
            return PriceFilter.price(this.order ? this.order.Totaalbedrag : 0, this.$i18n.locale);
        },

        /**
         * Construct URL for Apple Maps (opens native app on iPhone).
         * https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html#//apple_ref/doc/uid/TP40007899-CH5-SW1
         *
         * @return {string|undefined} URL or undefined if no address available
         */
        mapsUrl(): string|undefined {
            return !this.order ? undefined : UtilityServices.createMapsUrl(this.order.Plaats, this.order.Adres);
        },

        /**
         * Construct link to phone number to use in a-href.
         *
         * @return {string} string to use in a-href or empty string
         */
        telLink(): string {
            return UtilityServices.createTelLink(this.order ? this.order.Telefoon : '');
        },

        /**
         * Determines if the order just came in and didn't have a status yet.
         *
         * @return {boolean} True in case the order has not yet a status
         */
        isOrderWithoutStatus(): boolean {
            return !!this.order && (!this.order.orderStatus || this.order.orderStatus === 'None');
        },

        /**
         * Determines if the order has status new.
         *
         * @return True in case the order has status new
         */
        isOrderWithStatusNew(): boolean {
            return !!this.order && this.order.orderStatus === OrderStatus.NEW;
        },

        /**
         * Determines if the order has status cooking.
         *
         * @return True in case the order has status cooking
         */
        isOrderWithStatusCooking(): boolean {
            return !!this.order && this.order.orderStatus === OrderStatus.COOKING;
        },

        /**
         * Determines if the order has status ready.
         *
         * @return True in case the order has status ready
         */
        isOrderWithStatusReady(): boolean {
            return !!this.order && this.order.orderStatus === OrderStatus.READY;
        },

        /**
         * Pickup? (pickup has always a pickup time)
         *
         * @return {string|boolean} Return string with time (hh:mm) in case the order will be picked up or true/false otherwise
         */
        isPickup(): string|boolean {
            return OrderServices.isPickup(this.order as IOrder);
        },

        /**
         * Deliver asap?
         *
         * @return {boolean} Return true in case the order has to be delivered asap
         */
        isDeliverAsap(): boolean {
            return OrderServices.isDeliverAsap(this.order as IOrder);
        },

        /**
         * Pickup asap?
         * (Pickup asap is only possible if the type of delivery is manually chnaged from delivery to pickup.)
         *
         * @return {boolean} Return true in case the order has to be pickup-up asap
         */
        isPickupAsap(): boolean {
            return OrderServices.isPickupAsap(this.order as IOrder);
        },

        /**
         * Deliver at requested time?
         *
         * @return {string|null} Return string with time (hh:mm) in case the order is a delivery order and has a suggested time
         */
        isDeliverAtSpecificTime(): string|null {
            return OrderServices.isDeliverAtSpecificTime(this.order as IOrder);
        },

        /**
         * Determines if the order is a Thuisbezorgd order.
         *
         * @return {boolean} True in case the order is a custom order, false otherwise
         */
        isThuisbezorgdOrder(): boolean {
            return !!(this.order && this.order.channel === 'THUISBEZORGD');
        },

        /**
         * Time it took to confirm the order.
         * @return {number|null} Number of minutes or null in case not yet confirmed
         */
        timeToConfirm(): number|null {
            if (!this.order || !this.order.dateConfirmed || !this.order.date) {
                return null;
            }
            return Math.abs(moment(this.order.date).diff(this.order.dateConfirmed, 'minutes'));
        },

        /**
         * Time difference between time the order wat marked ready and the estimated delivery time.
         * @return {number|null} Number of minutes or null in case not yet ready
         */
        processingTime(): number|null {
            if (!this.order || !this.order.dateReady || !this.order.date) {
                return null;
            }
            return Math.abs(moment(this.order.dateReady).diff(this.order.date, 'minutes'));
        },

        darkMode(): boolean {
            return UtilityServices.isDarkMode()
        }
    },

    created() {
        this.isPreOrder = (this.$router?.currentRoute?.value?.path || '').indexOf('/preorder/') === 0
        UtilityServices.setScrollToTop();
        if (this.isPreOrder) {
            this.loadPreOrder();
        } else {
            this.loadOrder();
        }
        this.loadDefaultVatTariff();
        this.loadGeoLocationRestaurant();
    },

    mounted() {
        this.socket = this.createSocket();
        this.installVisibilityChangeListener();
    },

    beforeUnmount() {
        if (this.socket) {
            (this.socket as any).destroy();
        }
        this.removeVisibilityChangeListener();
    }
});

