export default {

    /**
     * Format given price, displaying two digits and using locale decimal separator.
     *
     * @param {number|string} price The number to display (float or integer)
     * @param {string} locale The locale; only nl-NL and en-US supported
     * @return {string} The given number formatted as price; eg 12,95
     */
    price: (price: number | string, locale: string) => {
        if (!locale) {
            throw new Error(`Locale is required "${locale}"`);
        }
        if (['nl-NL', 'en-US'].indexOf(locale) === -1) {
            throw new Error(`Locale is invalid "${locale}"`);
        }

        if (typeof price !== 'number') {
            // @ts-ignore
            price = parseFloat(price ? `${price}` : 0);
        }

        // en-US is always available, so we use it as starting point.
        const localeString = price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        // Unfortunately, by default Node does not include i18n data, so we have to workaround this.
        // https://stackoverflow.com/questions/23199909/using-tolocalestring-in-node-js#23200062
        if (locale.indexOf('nl') === 0) {
            return localeString
                .replace('.', '|')
                .replace(',', '.')
                .replace('|', ',');
        }
        return localeString;
    }
};
