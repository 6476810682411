
import {defineComponent} from 'vue';
import io, {Socket} from 'socket.io-client';
import NetworkServices from '../services/NetworkServices';
import UtilityServices from '../services/UtilityServices';
import Spinner from '../components/Spinner.vue';
import PriceFilter from '../components/PriceFilter';
import OrderServices from '../services/OrderServices';
import DateFormatFilter from '../components/DateFormatFilter';
import UCFirstFilter from '../components/UCFirstFilter';
import CeilFilter from '../components/CeilFilter';
import DeliveryDistance from '@/components/DeliveryDistance.vue';
import PriceAndPayment from "@/components/PriceAndPayment.vue";
import {IOrder} from '@/interfaces/order';
import {PositionAsDecimal} from '@/interfaces/geo';
import {WaitingTime} from '@/interfaces/waiting-time';
import {OrderLabel} from '@/enums/order-label';

export default defineComponent({

    components: {
        DeliveryDistance,
        PriceAndPayment,
        Spinner
    },
    props: {},
    data: function () {
        return {
            /** @type {IOrder[]=} */
            orders: undefined as IOrder[]|undefined,
            /** @type {PositionAsDecimal=} */
            geoLocationRestaurant: undefined as PositionAsDecimal|undefined,
            progress: false,
            /** @type {?string} */
            errorMessage: null as string|null,
            /** @type {?string} */
            infoMessage: null as string|null,
            /**
             * @type {?string} DateTime as string in format 2020-02-17T22:36:54.926Z (serialized Date object)
             */
            lastChange: null as string|null,
            /**
             * @type {?WaitingTime} Current waiting time in minutes
             */
            waitingTime: null as WaitingTime|null,
            socket: null as typeof Socket|null,
            /**
             * @type {boolean}
             */
            showErrorDetails: false
        };
    },
    methods: {

        toggleShowErrorDetails():void {
            this.showErrorDetails = !this.showErrorDetails;
        },

        /**
         * Determine the row classname based on order status.
         *
         * @param order Order
         * @return {string} classname(s)
         */
        getOrderStatusClass(order: IOrder): string {
            let styleClass = '';
            if (!order.orderStatus || order.orderStatus === 'None') {
                styleClass += ' bg-danger';
            } else if (order.orderStatus === 'New') {
                styleClass += ' bg-warning';
            } else if (order.orderStatus === 'Cooking') {
                styleClass += ' bg-info';
            } else if (order.orderStatus === 'Ready') {
                styleClass += ' bg-success';
            }

            if (order.channel === 'THUISBEZORGD') {
                styleClass += ' bg-thuisbezorgd';
            }
            return styleClass;
        },

        /**
         * Determine the order status icon / emoticon.
         *
         * @param order Order
         * @return {string} icon
         */
        getOrderStatusIcon(order: IOrder): string {
            // Use the Surrogate Pair Calculator to calculate 2xUTF-16 characters.
            // http://www.russellcottrell.com/greek/utilities/SurrogatePairCalculator.htm
            if (!order.orderStatus || order.orderStatus === 'None') {
                // 1F195
                return '\uD83C\uDD95';
            } else if (order.orderStatus === 'New') {
                return '\u2714';
            } else if (order.orderStatus === 'Cooking') {
                // 1F373
                return '\uD83C\uDF73';
            } else if (order.orderStatus === 'Ready' && (!order.labels || order.labels.indexOf(OrderLabel.PREORDER) === -1)) {
                // Car &#x1F697;
                return '\uD83D\uDE97';
            } else if (order.orderStatus === 'Ready' && order.labels && order.labels.indexOf(OrderLabel.PREORDER) !== -1) {
                // Calendar &#x1F4C5;
                return '\uD83D\uDCC5';
            }
            return '';
        },

        /**
         * Return delivery or requested delivery/pickup time for given order to show in orders overview.
         *
         * @param order Order
         * @returns Time of delivery to show
         */
        getDeliveryOrPickupTime(order: IOrder): string {
            return OrderServices.getDeliveryOrPickupTime(order);
        },

        /**
         * Valid street name?
         *
         * @param street Street name and number
         * @return Return true in case of valid address, false otherwise
         */
        isInvalidAddress(street: string): boolean {
            return OrderServices.isInvalidAddress(street);
        },

        /**
         * Initialize client socket and start listening for change messages.
         *
         * @return {Socket}
         */
        createSocket(): typeof Socket {
            const socket = io();
            socket.on('orders', (data: any) => {
                if (data.error) {
                    this.errorMessage = data.error;
                    this.infoMessage = this.$t('orders.messages.staleorders');
                    return;
                }

                this.errorMessage = null;
                this.infoMessage = null;

                // Because of serialization, we have to convert dates back to Date objects.
                const orders = data.orders as IOrder[];
                OrderServices.fixOrderDates(orders);

                this.orders = orders;
                this.lastChange = data.lastModified;
            });
            // Updates every 5 seconds.
            socket.on('waitingTime', (waitingTime: WaitingTime) => {
                this.waitingTime = waitingTime;
            });
            return socket;
        },

        /**
         * Get a fresh list of all orders.
         *
         * @return Promise which resolves on success and rejects on failure.
         */
        loadOrders(): Promise<void> {
            return new Promise((resolve, reject) => {
                this.progress = true;
                this.infoMessage = null;

                OrderServices.loadOrders()
                    .then((data: any) => {

                        // Reset error message.
                        this.errorMessage = null;

                        if (UtilityServices.deepEquals(this.orders, data.orders as IOrder[])) {
                            // Up to date; do nothing.
                            return;
                        }
                        this.orders = data.orders;
                        this.lastChange = data.lastModified;
                        resolve();
                    })
                    .catch(error => {
                        // Unauthorized, redirect to login-screen.
                        if (error.errorCode === 'UNAUTHORIZED') {
                            this.$router.push('/login');
                            return;
                        }

                        // The short readable error message "response.data.errorMessage" (if available).
                        this.errorMessage = NetworkServices.extractErrorMessage(error, this);

                        // Load cached order, so there's something to show at least.
                        OrderServices.loadOrders(false, true)
                            .then((data: any) => {
                                this.infoMessage = this.$t('orders.messages.staleorders');
                                this.orders = data.orders;
                                this.lastChange = data.lastModified;
                            })
                            .finally(() => {
                                reject();
                            });
                    })
                    .finally(() => {
                        this.progress = false;
                    })
            });
        },

        /**
         * Location of restaurant.
         */
        loadGeoLocationRestaurant(): void {
            OrderServices.loadGeoLocationRestaurant()
                .then(geoLocationRestaurant => this.geoLocationRestaurant = geoLocationRestaurant)
                .catch(error => {
                    // Unauthorized, redirect to the login screen.
                    if (error.errorCode === 'UNAUTHORIZED') {
                        this.$router.push('/login');
                        return;
                    }
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Listen for browser tab visibility changes.
         */
        installVisibilityChangeListener() {
            document.addEventListener('visibilitychange', this.visibilityChangeListener);
        },

        removeVisibilityChangeListener() {
            document.removeEventListener('visibilitychange', this.visibilityChangeListener);
        },

        /**
         * Disable websocket messages in case the tab is invisible (save resources).
         */
        visibilityChangeListener() {
            if (!this.socket) {
                return;
            }
            if (document.visibilityState === 'visible') {
                this.socket.open();
                this.loadOrders();
            } else {
                this.socket.close();
            }
        },

        /**
         * Open details on order row click.
         *
         * @param {IOrder} order the order to show details from
         */
        showDetails(order: IOrder): void {
            UtilityServices.saveCurrentScrollPosition();
            this.$router.push(`/order/${order.id}`);
        },

        // Previously filters.
        price: PriceFilter.price,
        hideEmpty: UtilityServices.hideEmpty,
        dateFormat: DateFormatFilter.dateFormat,
        ucfirst: UCFirstFilter.ucfirst,
        ceil: CeilFilter.ceil

    },

    computed: {
        /**
         * Calculate the total revenue.
         *
         * @return {number} The total revenue as float
         */
        totalRevenue(): number {
            return (this.orders || [] as IOrder[]).reduce((acc: number, order: IOrder) => {
                const totaalbedrag = (order.Totaalbedrag || '0').replace(',', '.');
                return (acc || 0) + (Number.parseFloat(totaalbedrag) || 0);
            }, 0);
        },

        /**
         * Calculate the total delivery distance.
         *
         * @return {number} The total delivery distance in meters
         */
        totalDeliveryDistance(): number {
            if (!this.orders || this.orders.length === 0) {
                return 0
            }
            return this.orders
                .filter(order => !order.labels || order.labels.length === 0 ? true : order.labels.indexOf(OrderLabel.PREORDER) === -1)
                .reduce((acc, order) => (acc || 0) + (order.geoRouteLength || 0), 0);
        },

        /**
         * Calculate the total Thuisbezorgd.nl provision.
         *
         * @return {number} The provision as float
         */
        thuisbezorgdProvision(): number {
            return (this.orders || [] as IOrder[]).reduce((accumulator: number, order: IOrder) => {
                if (order.channel !== 'THUISBEZORGD') {
                    return (accumulator || 0);
                }
                const totaalbedrag = Number.parseFloat((order.Totaalbedrag || '0').replace(',', '.'));
                return (accumulator || 0) + 0.21 + totaalbedrag * 0.14;
            }, 0);
        },

        /**
         * The number of Thuisbezorgd.nl orders.
         *
         * @return {number} The number of orders
         */
        thuisbezorgdCount(): number {
            if (!this.orders) {
                return 0;
            }
            return this.orders.filter(order => order.channel === 'THUISBEZORGD').length;
        },

        darkMode(): boolean {
            return UtilityServices.isDarkMode()
        }
    },

    created() {
        this.loadOrders()
            .finally(() => {
                UtilityServices.resetCurrentScrollPosition();
                this.loadGeoLocationRestaurant();
            });
    },

    mounted() {
        this.socket = this.createSocket();
        this.installVisibilityChangeListener();
    },

    beforeUnmount() {
        if (this.socket) {
            (this.socket as any).destroy();
        }
        this.removeVisibilityChangeListener();
    }
});

