// noinspection DuplicatedCode

export default {
    /**
     * Create datestamp for given day.
     *
     * @param now The date to create datestamp from, or empty for today
     * @returns Datestamp in format yyyymmdd
     */
    getDatestamp(now = new Date()): string {
        if (!now || !now.getMonth || !now.getDate || !now.getFullYear) {
            throw Error(`Invalid parameter type: ${now}, should be of type Date.`);
        }
        // 0-prefix for month and day.
        const month = (`0${now.getMonth() + 1}`).slice(-2);
        const day = (`0${now.getDate()}`).slice(-2);
        return `${now.getFullYear()}${month}${day}`;
    },

    /**
     * Parse datestamp yyyymmdd to Date object.
     * https://stackoverflow.com/questions/10638529/how-to-parse-a-date-in-format-yyyymmdd-in-javascript
     *
     * @param datestamp Datestamp in format yyyymmdd
     */
    parseDatestamp(datestamp: string) {
        if (!/^(\d){8}$/.test(datestamp)) {
            throw Error(`Invalid date '${datestamp}'`);
        }
        const y = parseInt(datestamp.slice(0, 4), 10);
        const m = parseInt(datestamp.slice(4, 6), 10) - 1;
        const d = parseInt(datestamp.slice(6, 8), 10);
        return new Date(y, m, d);
    },

    /**
     * Get today's date.
     * (convenient function to override during tests).
     *
     * @return {Date}
     */
    getCurrentDate() {
        return new Date();
    },

    /**
     * Create timestamp hh:mm.
     *
     * @param [date] The date
     * @param [withColon] If true, add colon between the hours and minutes
     */
    getTimestamp(date:Date = new Date(), withColon: boolean = false) {
        if (!date || !date.getMonth || !date.getDate || !date.getFullYear) {
            throw Error(`Invalid parameter type: ${date}, should be of type Date.`);
        }
        // 0-prefix for month and day.
        const hours = (`0${date.getHours()}`).slice(-2);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        return `${hours}${withColon ? ':' : ''}${minutes}`;

    }
};


