import {reactive, watch} from "vue";
import {ICheckout} from "@/interfaces/checkout";

const LOCALSTORAGE_KEY_CHECKOUT = 'checkout';

const initialCheckout: ICheckout = {

    /**
     * Clear for next use.
     */
    clear() {
        delete this.requestedTime;
        delete this.remark;
        delete this.paysWith;
        delete this.paysExact;
        delete this.paymentType;
    }
};

// Load from localStorage.
if (window.localStorage) {
    try {
        const checkoutString = window.localStorage.getItem(LOCALSTORAGE_KEY_CHECKOUT);
        if (checkoutString) {
            const checkoutFromStorage = JSON.parse(checkoutString);
            for (const p in checkoutFromStorage) {
                // @ts-ignore
                initialCheckout[p] = checkoutFromStorage[p];
            }
        }
    } catch (e) {
        // Ignore it, just start clean.
    }
}

/**
 * Internal cart store.
 */
export const checkoutStore: ICheckout = reactive<ICheckout>(initialCheckout);

/**
 * Watch changes and store it in local storage.
 */
watch(checkoutStore, () => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.setItem(LOCALSTORAGE_KEY_CHECKOUT, JSON.stringify(checkoutStore));
});
