
import {defineComponent} from 'vue';
import PriceFilter from "@/components/PriceFilter";

export default defineComponent({

    props: {
        order: {
            type: Object,
            required: false
        }
    },
    methods: {
        // Filters.
        price: PriceFilter.price,
    }
});

