
import OrderServices from '../services/OrderServices';
import DateFormatFilter from './DateFormatFilter';
import NetworkServices from '../services/NetworkServices';
import moment from 'moment';
import {defineComponent} from 'vue';
import {ISentMessage} from '@/interfaces/sent-message';

export default defineComponent({

    props: {
        /**
         * Phone number
         */
        phoneNumber: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data: function () {
        return {
            sentMessages: undefined,
            errorMessage: null
        } as {sentMessages?:ISentMessage[], errorMessage:string|null};
    },
    methods: {
        /**
         * Get a list of all sent messages.
         */
        loadMessages() {
            OrderServices.loadSentMessages(this.phoneNumber as string)
                .then(/** @type {ISentMessage[]} */data => {
                    // Reset error message.
                    this.errorMessage = null;
                    this.sentMessages = data;
                })
                .catch(error => {
                    this.errorMessage = NetworkServices.extractErrorMessage(error, this);
                });
        },

        /**
         * Test if the given day is the same day as today.
         *
         * @param {ISentMessage} message The message to be checked
         * @return {boolean} true if same day, false otherwise
         */
        isToday(message: any): boolean {
            return moment().isSame(message.date, 'day');
        },

        // Filters.
        dateFormat: DateFormatFilter.dateFormat

    },
    created() {
        this.loadMessages();
    }
});

