import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-078e4c82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-1 px-1" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_3 = { class: "login-block" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "username" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { for: "password" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "spinner-container ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      action: "",
      method: "post",
      novalidate: "novalidate",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("loginform.title.login")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("loginform.label.username")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "username",
            name: "username",
            placeholder: "",
            style: {"max-width":"16em"},
            autocapitalize: "off",
            autocorrect: "off",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
            autofocus: "autofocus"
          }, null, 512), [
            [_vModelText, _ctx.username]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("loginform.label.password")), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            class: "form-control",
            id: "password",
            name: "password",
            placeholder: "",
            style: {"max-width":"16em"},
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
          }, null, 512), [
            [_vModelText, _ctx.password]
          ])
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary",
          disabled: _ctx.progress
        }, _toDisplayString(_ctx.$t("loginform.button.login")), 9, _hoisted_9),
        (_ctx.progress)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
              _createVNode(_component_spinner)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}