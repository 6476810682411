// Ready translated locale messages
export default {
    'en-US': {
        types: {
            paymenttype: {
                iDeal: 'iDeal',
                contant: 'Cash',
                creditcard: 'Creditcard',
                PIN: 'PIN',
                paymentrequest: 'Payment request'
            }
        },
        app: {
            buttons: {
                togglefont: 'Toggle Font Size',
                logout: 'Logout'
            },
            messages: {
                softwareversion: 'Sitedish app software version'
            },
            labels: {
                lastupdate: 'Last update',
                lastupdatetimeformat: 'HH:mm:ss'
            }
        },
        dishes: {
            labels: {
                deposit: 'Including deposit',
                vat: 'Including VAT'
            }
        },
        order: {
            labels: {
                timeformat: 'HH:mm',
                dateformat: 'dddd MMM D',
                lastchange: 'Last change',
                lastchangetimeformat: 'HH:mm:ss',
                orderstatusnew: 'New',
                orderstatusack: 'Acknowledged',
                orderstatuscooking: 'Kitchen',
                orderstatusreadypickup: 'Picked up',
                orderstatusreadydelivery: 'Delivered',
                ordertime: 'Order time',
                confirmtime: 'Confirm time',
                readytime: 'Ready time',
                pickuptime: 'Pickup time',
                deliverytime: 'Delivery time',
                customerwish: 'preferred',
                notconfirmed: 'Not yet confirmed',
                asap: 'asap',
                total: 'Total',
                payswith: 'pays with',
                paysdue: 'pays due',
                notpayedyet: 'Not payed',
                preferredpayment: 'Prefers to pay with',
                dishes: 'Dishes',
                remarks: 'Remarks',
                name: 'Name',
                address: 'Address',
                phone: 'Phone',
                email: 'Email',
                openmaps: 'Show on map',
                clicktocall: 'Tap to call',
                back: 'Back to orders',
                unknown: 'Unknown',
                tip: 'Tip',
                savingsaccount: 'Points',
                savingsaccountthisorder: 'This order:',
                savingsaccounttotal: 'total:'
            },
            titels: {
                ideal: 'Online payed by iDeal',
                timetoconfirm: 'Time it took to confirm the order',
                timetoprocess: 'Time it took to process the complete order',
            },
            messages: {
                orderdoesnotexist: 'The order does not exist (anymore). Could be deleted or is expired.',
                staleorder: 'The order is not up-to-date.'
            }
        },
        orders: {
            messages: {
                noorders: 'There are no orders yet.',
                noaddress: 'No address',
                staleorders: 'The list of orders is not up-to-date!'
            },
            labels: {
                revenue: 'revenue',
                waitingtime: 'waiting',
                totals: 'totals',
                preorders: 'pre-orders',
                orders: 'orders',
                provision: 'provision',
                total: 'Total #',
                payed: 'Payed',
                payedwithpin: 'Payed with PIN',
                payedwithcash: 'Payed with cash',
                payedwithpaymentrequest: 'Payed with payment request',
                payedwithideal: 'Payed online with iDeal',
                payedwithcreditcard: 'Payed with creditcard',
                payedonlinewithcreditcard: 'Payed online with creditcard',
                lastchange: 'Last change',
                lastchangetimeformat: 'HH:mm:ss',
                hide: 'Hide',
                show: 'Show',
                technicaldetails: 'technical details'
            }
        },
        totals: {
            labels: {
                date: 'Gross revenue',
                dateformat: 'dddd, MMMM Do YYYY',
                ordertypesitedish: 'Online Sitedish',
                ordertypethuisbezorgd: 'Online Thuisbezorgd.nl',
                ordertypecard: 'PIN / creditcard',
                ordertypecash: 'Cash',
                ordertypepaymentrequest: 'Paymentrequest',
                ordertypeunspecified: 'Not specified',
                preorder: 'pre-order',
                total: 'Total',
                print: 'Print',
                back: 'Back to orders',
                includingpreorders: 'including pre-orders',
                excludingprepaid: 'excluding prepaid orders'
            }
        },
        logging: {
            labels: {
                refresh: 'Refresh',
                waitingtime: 'waiting time',
                waitingtimedetails: 'waiting time explanation',
                abouttoupdate: 'About to update',
                rounded: 'rounded',
                custom: 'custom',
                lastupdate: 'last update',
                minutes: 'minutes',
                hours: 'hours',
                change: 'Change',
                ok: 'Ok',
                cancel: 'Cancel'
            },
            texts: {
                waitingtimeoverridden: 'Auto calculated waiting time is overridden by custom time!'
            }
        },
        loginform: {
            title: {
                login: 'Log in'
            },
            messages: {
                error: {
                    usernamepasswordrequired: 'Username and password are both required.',
                    invalidusernameorpassword: 'Invalid username or password.'
                }
            },
            label: {
                username: 'Username',
                password: 'Password'
            },
            button: {
                login: 'Log in'
            }
        },
        sentmessages: {
            label: {
                sentmessages: 'Sent messages',
                timedate: 'Time / date',
                message: 'Message',
                timedateformat: 'dd D MMMM/ HH:mm',
                timeformat: 'HH:mm',
                today: 'Today'
            }
        }
    },
    'nl-NL': {
        types: {
            paymenttype: {
                iDeal: 'iDeal',
                contant: 'Contant',
                creditcard: 'Creditcard',
                PIN: 'PIN',
                paymentrequest: 'Betaalverzoek'
            }
        },
        app: {
            buttons: {
                togglefont: 'Groot/klein',
                logout: 'Uitloggen'
            },
            messages: {
                softwareversion: 'Sitedish app software versie'
            },
            labels: {
                lastupdate: 'Laatste update',
                lastupdatetimeformat: 'HH:mm:ss'
            }
        },
        dishes: {
            labels: {
                deposit: 'Waarvan statiegeld',
                vat: 'Waarvan BTW'
            }
        },
        order: {
            labels: {
                timeformat: 'HH:mm',
                dateformat: 'dddd D MMM',
                lastchange: 'Laatste wijziging',
                lastchangetimeformat: 'HH:mm:ss',
                orderstatusnew: 'Nieuw',
                orderstatusack: 'Bevestigd',
                orderstatuscooking: 'Keuken',
                orderstatusreadypickup: 'Afhalen / Afgehaald',
                orderstatusreadydelivery: 'Bezorging / Bezorgd',
                ordertime: 'Besteltijd',
                confirmtime: 'Bevestigingstijd',
                readytime: 'Klaargemeld',
                pickuptime: 'Afhaaltijd',
                deliverytime: 'Bezorgtijd',
                customerwish: 'klantwens',
                notconfirmed: 'Nog niet bevestigd',
                asap: 'zsm',
                total: 'Totaalbedrag',
                payswith: 'betaalt met',
                paysdue: 'betaalt gepast',
                notpayedyet: 'Niet betaald',
                preferredpayment: 'Betalingsvoorkeur',
                dishes: 'Gerechten',
                remarks: 'Opmerkingen',
                name: 'Naam',
                address: 'Adres',
                phone: 'Telefoon',
                email: 'E-mail',
                openmaps: 'Toon op kaart',
                clicktocall: 'Tap om te bellen',
                back: 'Terug naar overzicht',
                unknown: 'Onbekend',
                tip: 'Fooi',
                savingsaccount: 'Punten',
                savingsaccountthisorder: 'Deze bestelling:',
                savingsaccounttotal: 'totaal gespaard:'
            },
            titels: {
                ideal: 'Online betaald iDeal',
                timetoconfirm: 'Tijd die het kostte om de order te bevestigen',
                timetoprocess: 'Tijd die het kostte om de order te bereiden',
            },
            messages: {
                orderdoesnotexist: 'De bestelling bestaat niet (meer). Mogelijk verwijderd of verlopen.',
                staleorder: 'The bestelling is niet actueel!'
            }
        },
        orders: {
            messages: {
                noorders: 'Er zijn nog geen bestellingen.',
                noaddress: 'Geen adres bekend',
                staleorders: 'De lijst met bestellingen is niet actueel!'
            },
            labels: {
                revenue: 'omzet',
                waitingtime: 'wachttijd',
                totals: 'totalen',
                preorders: 'vooruitbestellingen',
                orders: 'Bestellingen',
                provision: 'provisie',
                total: 'Totaal #',
                payed: 'Betaald',
                payedwithpin: 'Betaald met PIN',
                payedwithcash: 'Contant betaald',
                payedwithpaymentrequest: 'Betaald via betaalverzoek',
                payedwithideal: 'Online betaald via iDeal',
                payedwithcreditcard: 'Betaald met creditcard',
                payedonlinewithcreditcard: 'Online betaald met creditcard',
                lastchange: 'Laatste wijziging',
                lastchangetimeformat: 'HH:mm:ss',
                hide: 'Verberg',
                show: 'Toon',
                technicaldetails: 'technische details'
            }
        },
        totals: {
            labels: {
                date: 'Bruto omzet',
                dateformat: 'dddd D MMMM YYYY',
                ordertypesitedish: 'Online via Sitedish',
                ordertypethuisbezorgd: 'Online via Thuisbezorgd.nl',
                ordertypecard: 'PIN / creditcard',
                ordertypecash: 'Contant',
                ordertypepaymentrequest: 'Betaalverzoek',
                ordertypeunspecified: 'Niet gespecificeerd',
                preorder: 'vooruitbestelling',
                total: 'Totaal',
                print: 'Print',
                back: 'Terug naar bestellingen',
                includingpreorders: 'waarvan vooruitbestellingen',
                excludingprepaid: 'excl. vooruitbetaald'
            }
        },
        logging: {
            labels: {
                refresh: 'Ververs',
                waitingtime: 'Wachttijd',
                waitingtimedetails: 'Wachttijd uitleg',
                abouttoupdate: 'Update binnenkort',
                rounded: 'afgerond',
                custom: 'handmatig',
                lastupdate: 'bijgewerkt om',
                minutes: 'minuten',
                hours: 'uur',
                change: 'Wijzig',
                ok: 'Ok',
                cancel: 'Annuleren'
            },
            texts: {
                waitingtimeoverridden: 'Wachttijd is handmatig gezet!'
            }
        },
        loginform: {
            title: {
                login: 'Inloggen'
            },
            messages: {
                error: {
                    usernamepasswordrequired: 'Gebruikersnaam en wachtwoord zijn beide verplicht.',
                    invalidusernameorpassword: 'Ongeldige gebruikersnaam of wachtwoord.'
                }
            },
            label: {
                username: 'Gebruikersnaam',
                password: 'Wachtwoord'
            },
            button: {
                login: 'Log in'
            }
        },
        sentmessages: {
            label: {
                sentmessages: 'Verzonden berichten',
                timedate: 'Tijd / datum',
                message: 'Bericht',
                timedateformat: 'dd D MMMM / HH:mm',
                timeformat: 'HH:mm',
                today: 'Today'
            }
        }
    }
};
