// noinspection DuplicatedCode

let scrollYPosition: any;

// noinspection JSUnusedGlobalSymbols
export default {
    /**
     * Deep compare object a and b.
     *
     * @param {Object} a Object to compare
     * @param {Object} b Object to compare
     * @return True in case a and b are deeply equal
     */
    deepEquals(a: any, b: any): boolean {
        if (!a && !b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }
        return JSON.stringify(a) === JSON.stringify(b);
    },

    /**
     * Create link to phone number to use in a-href.
     *
     * @param phone Phone number
     * @return string to use in a-href
     */
    createTelLink(phone: string): string {
        if (!phone || !phone.trim()) {
            return '';
        }

        // Remove all spaces and some other characters.
        phone = phone.replace(/[\s-()]+/g, '');

        // Add country code in case number starts with 0 and has 10 digits.
        if (phone.length === 10 && phone[0] === '0') {
            phone = phone.replace('0', '+31');
        }
        return phone;
    },

    /**
     * Vue filter: Hide empty objects.
     *
     * @param value Object to display
     * @return {string} Value as string
     */
    hideEmpty(value: any): string {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            return value;
        }
        if (typeof value === 'number') {
            return `${value}`;
        }
        if (Array.isArray(value) && value.length) {
            return JSON.stringify(value);
        }
        if (Object.keys(value).length) {
            return JSON.stringify(value);
        }
        return '';
    },

    /**
     * Save the current vertical scroll position.
     */
    saveCurrentScrollPosition(): void {
        scrollYPosition = window.scrollY;
    },

    /**
     * Reset to the last known vertical scroll position.
     */
    resetCurrentScrollPosition(): void {
        if (scrollYPosition || scrollYPosition === 0) {
            setTimeout(() => {
                window.scrollTo(0, scrollYPosition);
            }, 0);
        }
    },

    setScrollToTop(): void {
        window.scrollTo(0, 0);
    },

    /**
     * Smooth scroll given element to bottom.
     *
     * @param element Element to scroll
     */
    setScrollToBottom(element?: HTMLElement|null): void {
        if (element) {
            // https://stackoverflow.com/questions/270612/scroll-to-bottom-of-div
            element.scroll({top: element.scrollHeight, behavior: 'smooth'});
        }
    },

    /**
     * Scroll smooth to given anchor element.
     * https://www.w3docs.com/snippets/javascript/how-to-create-smooth-scrolling-when-clicking-an-anchor-link.html
     *
     * @param elmId Id of element
     * @param $event The click event
     */
    scrollSmoothTo: (elmId: string, $event: MouseEvent) => {
        const elm = document.getElementById(elmId);
        if (elm) {
            if ($event) {
                $event.preventDefault();
            }
            elm.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        }
    },

    /**
     * Determines if the current browser operates in "dark mode".
     *
     * @return True if dark mode, false otherwise
     */
    isDarkMode(): boolean {
        return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },

    /**
     * Convert time in seconds to something more readable; eg: 10hours, 20days etc
     * @param time Time in seconds, cannot be negative
     */
    timeHumanReadable(time: number): string {
        if (time < 0) {
            throw Error('Time cannot be negative: ' + time);
        }
        if (time < 60 * 5) {
            // Number of seconds.
            return `${Math.round(time)}s`;
        }
        if (time < 60 * 60 * 2) {
            // Number of minutes.
            return `${Math.round(time / 60)}m`;
        }
        if (time < 60 * 60 * 24 * 3) {
            // Number of hours.
            return `${Math.round(time / 60 / 60)}h`;
        }
        // Number of days.
        return `${Math.round(time / 60 / 60 / 24)}d`;
    },

    /**
     * Set class on given element.
     *
     * @param target Target element
     * @param className Class name
     * @param timeout Number of ms before class is removed
     */
    setClassOnElement(target: HTMLElement, className: string, timeout: number = 0) {
        if (!target) {
            throw Error('target parameter is required.');
        }
        if (!className) {
            throw Error('className parameter is required.');
        }

        const classList = target.classList;
        classList.add(className);
        if (timeout) {
            setTimeout(() => classList.remove(className), timeout);
        }
    },

    /**
     * Wait for the requested element selector on the given target element.
     *
     * @param target Target element to listen for mutations
     * @param selector The element to query and wait for
     * @return Promise with list of nodes found or reject in case the browser does not support mutation observer
     */
    waitForElement(target: HTMLElement, selector: string): Promise<NodeListOf<Element>> {
        if (!target) {
            throw Error('target parameter is required.');
        }
        if (!selector) {
            throw Error('selector parameter is required.');
        }
        if (!target.querySelector) {
            throw Error('target parameter is not of type HTMLElement.');
        }

        return new Promise<NodeListOf<Element>>((resolve, reject) => {
            // The element we search for might already be there, first test for it.
            const resultNodes = target.querySelectorAll(selector);
            if (resultNodes.length) {
                resolve(resultNodes);
                return;
            }
            // Test for mutation observer support.
            if (!('MutationObserver' in window)) {
                // No support for mutation observer, reject the promise.
                reject('MutationObserver not supported');
                return;
            }
            const observer = new MutationObserver(() => {
                const resultNodes2 = target.querySelectorAll(selector);
                if (resultNodes2.length) {
                    observer.disconnect();
                    resolve(resultNodes2);
                }
                // Requested element not found yet, keep waiting.
            });

            observer.observe(target, {attributes: false, childList: true, subtree: true});
        });
    },

    /**
     * Test the format of an order uuid e.g. de47a54c-e45f-4f86-92ca-59a54926c0e5.
     *
     * @param {string} orderId in format de47a54c-e45f-4f86-92ca-59a54926c0e5
     * @return {boolean} true in case the format validates
     */
    testOrderUUIDFormat(orderId: string): boolean {
        return /^[0-9a-z-]{10,}$/.test(orderId);
    },

    /**
     * Construct URL for Apple Maps (opens native app on iPhone).
     * https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html#//apple_ref/doc/uid/TP40007899-CH5-SW1
     *
     * @param city City name
     * @param address Street and house number
     * @return {string|undefined} URL or undefined if no address available
     */
    createMapsUrl(city:string|undefined, address:string|undefined): string|undefined {
        if (city && address && city.trim && address.trim) {

            city = city.trim();
            address = address.trim();

            // Fix for "Haarle", otherwise it's interpreted as Haarlem.
            // noinspection SpellCheckingInspection
            if (city.toLowerCase() === 'haarle') {
                city = '7448 Haarle'
                // Be even more specific for "Stationsweg"
                if (address.toLowerCase().includes('stationsweg')) {
                    city = '7448RR Haarle'
                }
            }

            // Return as destination address.
            // https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
            return 'https://maps.apple.com/?daddr='
                + encodeURIComponent(address) + ',%20'
                + encodeURIComponent(city);
        }
        return undefined;
    },


};


