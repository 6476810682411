import {ICart, ICartItem} from "@/interfaces/cart";
import {reactive, watch} from "vue";
import {IProduct, IProductChoice} from "@/interfaces/product";
import {TypeOfDelivery} from "@/enums/type-of-delivery";
import {IGerecht} from "@/interfaces/order";
import PriceFilter from "@/components/PriceFilter";

const LOCALSTORAGE_KEY_CART = 'cart';

const listeners: ((id: string) => void)[] = [];

const initialCartStore: ICart = {
    typeOfDelivery: TypeOfDelivery.PICKUP,
    items: [],

    /**
     * Add new product to cart.
     *
     * @param product The product
     * @param options Extra options (optional, default [])
     * @param amount Number of products to add (optional, default 1)
     */
    add(product: IProduct, options: IProductChoice[] = [], amount: number = 1) {

        // The id is based on product id and the optional options.
        const id = [product.id, ...options.map(o => o.id)].join('-');

        // Notify all listeners.
        setTimeout(() => {
            listeners.forEach(l => l(id));
        }, 0);

        // Increment existing cart item?
        const existingItem = this.items.find(i => i.id === id);
        if (existingItem) {
            existingItem.amount += amount;
            return;
        }
        // Push to cart as new item.
        this.items.push({
            id,
            product: product,
            amount,
            options
        });
    },

    /**
     * Decrement the amount with one and remove the item in case the amount is zero.
     *
     * @param item The item to decrease the amount from
     */
    decrementAmount(item: ICartItem) {
        item.amount--;
        if (item.amount <= 0) {
            // Remove the item.
            this.items.splice(this.items.indexOf(item), 1);
        }
    },

    /**
     * Return the total price in cents.
     *
     * @param item The item to calculate the total price for
     * @return The total price for this item in cents
     */
    totalPrice(item: ICartItem): number {
        // We don't differentiate between delivery and pickup (yet).
        return item.amount * item.product.price +
            // The options.
            item.options.reduce((v, option) => v + option.price, 0);
    },

    /**
     * The complete name of the product and options (options are optional)
     * .
     * @param item
     */
    itemName(item: ICartItem): string {
        return [item.product.name, ...item.options.map(i => i.name)].join(' - ');
    },

    /**
     * Calculate the total price of the cart.
     * The price WITHOUT delivery costs!
     *
     * @return The total price ion cents
     */
    totalPriceCart(): number {
        return this.items.reduce((previousValue, item) => previousValue + this.totalPrice(item), 0);
    },

    /**
     * Delivery costs in cents.
     * Or 0 in case there are no costs.
     */
    deliveryCosts(): number {
        if (this.typeOfDelivery === TypeOfDelivery.DELIVERY) {
            if (this.totalPriceCart() < 4200) {
                return 250;
            }
        }
        return 0;
    },

    /**
     * Convert items list to list of dishes.
     */
    getDishes(): IGerecht[] {
        return this.items.map(item => {
            const totalItemPrice = cartStore.totalPrice(item);
            return {
                Gerecht_Aantal: item.amount,
                Gerecht_Naam: item.product.name,
                // eg. '8 stuks', or an array ['8 stuks', 'Avocado topping'].
                Gerecht_Extra: item.options.map(option => option.name),
                // Single item price.
                Gerecht_Prijs: PriceFilter.price(totalItemPrice / item.amount / 100, 'en-US'),
                // eg '9.15'; US notation of decimal separator or "GRATIS".
                // Total price of all units, including deposit costs and VAT.
                Gerecht_Subtotaal: PriceFilter.price(totalItemPrice / 100, 'en-US'),
                Gerecht_BTW: 9,
                // eg '0.15'; US notation of decimal separator.
                // Deposit costs "statiegeld" of single unit.
                Gerecht_Statiegeld: '0.00'
            };
        });
    },

    /**
     * Clear the cart for next use.
     */
    clear(): void {
        this.items.length = 0;
    },

    /**
     * Add new listener for new products and return remove function.
     *
     * @param listener Listener to add
     */
    addNewItemListener(listener: (id: string) => void): () => void {
        listeners.push(listener);
        // Return remove function.
        return function () {
            const i = listeners.indexOf(listener);
            if (i !== -1) {
                listeners.slice(i, 1);
            }
        };
    }
};

// Load from localStorage.
if (window.localStorage) {
    try {
        const cartString = window.localStorage.getItem(LOCALSTORAGE_KEY_CART);
        if (cartString) {
            const cartFromStorage = JSON.parse(cartString);
            for (const p in cartFromStorage)
                // eslint-disable-next-line no-prototype-builtins
                if (initialCartStore.hasOwnProperty(p)) {
                    // @ts-ignore
                    initialCartStore[p] = cartFromStorage[p];
                }
        }

    } catch (e) {
        // Ignore it, just start clean.
    }

}

/**
 * Internal cart store.
 */
export const cartStore: ICart = reactive<ICart>(initialCartStore);

/**
 * Watch changes and store it in local storage.
 */
watch(cartStore, () => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.setItem(LOCALSTORAGE_KEY_CART, JSON.stringify(cartStore));
});
