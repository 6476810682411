export default {

    /**
     * Round number up.
     *
     * @param {*} v to round
     * @return {string} rounded
     */
    ceil: (v: any) => {
        if (typeof v === 'number') {
            return `${Math.ceil(/** @type {number} */ v)}`;
        }
        return v ? `${Math.ceil(parseInt(`${v}`, 10))}` : '0';
    }
};
