

import axios from 'axios';
import networkServices from './services/NetworkServices';
import io, {Socket} from 'socket.io-client';
import dateFormatFilter from './components/DateFormatFilter';
import {defineComponent} from 'vue';
import utilityServices from '@/services/UtilityServices';
import logServices from "@/services/LogServices";

const STORAGE_KEY_FONTSIZE = 'fontsize';
const storage = window.localStorage;

export default defineComponent({

    data() {
        let fontSize = 1;
        // Get the font size from local storage, if available.
        if (storage && storage.getItem(STORAGE_KEY_FONTSIZE)) {
            fontSize = Number.parseInt(storage.getItem(STORAGE_KEY_FONTSIZE) || '1');
        }

        const isDev = window.location.host.indexOf('localhost') === 0;

        return {
            fontSize: fontSize as number,
            isDev: isDev as boolean,
            /**
             * @type {string|null} DateTime as string in format 2020-02-17T22:36:54.926Z (serialized Date object)
             */
            time: '' as string|null,
            version: '' as string|null,
            /** @type {number} Uptime of the NodeJS process in seconds. */
            uptime: 0 as number,
            socket: null as typeof Socket|null,
            /**
             * @type {number} Offset in minutes to add to the time displayed.
             */
            timezoneOffset: 0,
            versionIntervalId: 0,
            $router: this.$router
        };
    },

    methods: {

        /**
         * Toggle the font size and save the last used setting.
         */
        toggleFont() {
            this.fontSize = this.fontSize === 1 ? 2 : 1;
            updateFontClass(this.fontSize);
            // Save it in local storage.
            if (storage) {
                storage.setItem(STORAGE_KEY_FONTSIZE, `${this.fontSize}`);
            }
        },

        /**
         * Logout; invalidate the session.
         */
        logout() {
            axios
                .get('/api/logout')
                .then(() => {
                    this.$router.push('/login');
                })
                .catch(error => {
                    // eslint-disable-next-line
                    logServices.error('Error logging out: ', error);
                });
        },

        /**
         * Get the software version from the server.
         */
        receiveVersion() {
            axios
                .get('/api/version')
                .then(response => {
                    if (!this.version) {
                        this.version = response.data.version;
                        this.uptime = response.data.uptime;
                    } else if (this.version !== response.data.version) {
                        // New version detected, refresh the complete page.
                        window.location.reload();
                    }
                })
                .catch(error => this.version = networkServices.extractErrorMessage(error, this));
        },

        /**
         * Get the software version from the server.
         *
         * @return {Promise<number|Error>}
         */
        receiveTimezoneOffset(): Promise<number> {
            return new Promise((resolveFn, rejectFn) => {
                axios.get('/api/timezoneoffset')
                    .then(response => resolveFn(response.data.timezoneOffset))
                    .catch(error => rejectFn(networkServices.extractErrorMessage(error, this)));
            });
        },

        /**
         * Initialize client socket and start listening for change messages.
         *
         * @return {Socket}
         */
        createSocket() {
            const socket = io();
            socket.on('time', (msg: string) => this.time = msg);
            return socket;
        },

        /**
         * Listen for browser tab visibility changes.
         */
        installVisibilityChangeListener() {
            document.addEventListener('visibilitychange', this.visibilityChangeListener);
        },

        /**
         * Remove listen for browser tab visibility changes.
         */
        removeVisibilityChangeListener() {
            document.removeEventListener('visibilitychange', this.visibilityChangeListener);
        },

        /**
         * Disable websocket messages in case the tab is invisible (save resources).
         */
        visibilityChangeListener() {
            if (this.socket) {
                if (document.visibilityState === 'visible') {
                    this.socket.open();
                } else {
                    this.socket.close();
                }
            }
        },

        /**
         * Change re-authenticate on route change.
         */
        routeChangeListener() {
            if (!this.socket) {
                return;
            }
            this.socket.close();
            this.socket.open();
        },

        /**
         * Determine the difference in time between the server and client.
         * Add the difference to the client time, so the times appear as local time.
         */
        initializeTimezoneOffset() {
            this.receiveTimezoneOffset()
                .then((serverTimezoneOffset: number) => {
                    const clientTimezoneOffset = new Date().getTimezoneOffset();
                    const timezoneOffset = (clientTimezoneOffset - serverTimezoneOffset);
                    dateFormatFilter.setTimezoneOffset(timezoneOffset);
                });
        },

        // Filters.
        dateFormat: dateFormatFilter.dateFormat
    },

    computed: {
        /**
         * Display the uptime as
         */
        uptimeHumanReadable(): string {
            return utilityServices.timeHumanReadable(this.uptime);
        }
    },
    mounted() {
        // Check the version every minute and reload the application in case of version update.
        this.receiveVersion();
        this.versionIntervalId = setInterval(this.receiveVersion, 60000);

        this.socket = this.createSocket();
        this.installVisibilityChangeListener();
        this.$router.afterEach(() => {
            this.routeChangeListener();
        });
        this.initializeTimezoneOffset();

        // Restore last known font size.
        updateFontClass(this.fontSize);
    },

    beforeUnmount() {
        if (this.socket) {
            (this.socket as any).destroy();
        }
        this.removeVisibilityChangeListener();
        if (this.versionIntervalId) {
            clearInterval(this.versionIntervalId);
        }
    }
});

/**
 * Set "largefont" class based on font size; 1 (normal) or 2 (lage).
 *
 * @param {number} fontSize Font size 1 or 2
 */
function updateFontClass(fontSize: number): void {
    if (fontSize !== 1 && fontSize !== 2) {
        throw Error(`Invalid font size, cannot change application font size: "${fontSize}"`);
    }
    const appContainerDiv = document.getElementById('app-container');
    if (!appContainerDiv) {
        throw Error('App container div not found');
    }

    // Add or remove "largefont" class.
    const oldClassValue = appContainerDiv.getAttribute('class') || '';
    if (fontSize === 1) {
        appContainerDiv.setAttribute('class', oldClassValue.replace(/ *largefont */, ''));
    } else {
        appContainerDiv.setAttribute('class', `${oldClassValue} largefont`);
    }
}

