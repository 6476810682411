import axios from 'axios';
import {IGiftCard} from "@/interfaces/gift-card";
import {IGerecht, IOrder} from "@/interfaces/order";
import {IResponseError} from "@/interfaces/error";
import DateServices from "@/services/DateServices";
import {IProduct} from "@/interfaces/product";
import {IPublicConfiguration} from "@/interfaces/configuration";
import {TypeOfDelivery} from "@/enums/type-of-delivery";
import UtilityServices from '@/services/UtilityServices';

const REGEXP_PICKUP = /^Afhaal:? ?/i;
const REGEXP_DELIVERY_TIME = /^[0-9]{1,2}:[0-9]{2}$/;
const REGEXP_BEZORGTIJD = /.*?([0-9]{1,2}:[0-9]{2})$/i;

const CHANNEL_TYPE_SITEDISH = 'SITEDISH';

const TYPE_OF_DELIVERY_PICKUP = 'pickup';
const TYPE_OF_DELIVERY_DELIVERY = 'delivery';

// Cache the config.
let config: any;

// noinspection DuplicatedCode
export default {

    /**
     * Get a fresh list of all orders.
     *
     * @param preOrders If true, load pre-orders instead of today's orders
     * @param cached If true, load cached orders (in case of load error)
     * @return Promise that resolves with list of orders and last modified date or rejects with error
     */
    loadOrders(preOrders = false, cached = false): Promise<{orders: IOrder[], lastModified: Date}|IResponseError|Error> {
        return new Promise((resolve, reject) => {
            axios.get(preOrders ? '/api/preorders' : `/api/orders${cached ? '?cached=true' : ''}`)
                .then(response => {

                    // Data structure is a little different for pre-orders, fix it here.
                    const data = preOrders ? {orders: response.data, lastModified: new Date()} : response.data;

                    // Check to see if the orders are there.
                    if (!data || !Array.isArray(data.orders)) {
                        throw Error(`Expecting to receive an array, but received: "${data.orders}"`);
                    }

                    // Because of serialization, we have to convert dates back to Date objects.
                    this.fixOrderDates(data.orders as IOrder[]);
                    resolve(data);

                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            return reject({
                                errorCode: 'UNAUTHORIZED',
                                errorMessage: 'User is not authorized to receive orders'
                            });
                        }
                    }
                    reject(error);
                });
        });
    },

    /**
     * Get a list of all orders grouped by payment type.
     *
     * @param date The date to show orders for
     * @return Promise that resolves with list of orders
     */
    loadOrdersByPaymentType(date: Date): Promise<{type: string, orders: IOrder[]}[]|IResponseError|Error> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/ordersbypaymenttype?date=${DateServices.getDatestamp(date)}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            return reject({
                                errorCode: 'UNAUTHORIZED',
                                errorMessage: 'User is not authorized to receive orders'
                            });
                        }
                    }
                    reject(error);
                });
        });
    },

    /**
     * Get a list of all gift cards created or deleted today.
     *
     * @param date The date to show giftcards for
     * @return Promise that resolves with list of gift cards
     */
    loadGiftCards(date: Date): Promise<IGiftCard[]> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/giftcards?date=${DateServices.getDatestamp(date)}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            return reject({
                                errorCode: 'UNAUTHORIZED',
                                errorMessage: 'User is not authorized to receive orders'
                            });
                        }
                    }
                    reject(error);
                });
        });
    },

    /**
     * Get all the (public) products.
     *
     * @return Promise with the list of orders
     */
    loadProducts(): Promise<IProduct[]> {
        return new Promise((resolve, reject) => {
            fetch('/api/products')
                .then((response: Response) => {
                    if (response.status !== 200) {
                        reject({
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    response.json()
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        });
    },

    /**
     * Load public configuration.
     *
     * @return Promise with the configuration
     */
    loadConfig(): Promise<IPublicConfiguration> {
        return new Promise((resolve, reject) => {
            fetch('/api/config')
                .then((response: Response) => {
                    if (response.status !== 200) {
                        reject({
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    response.json()
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        });
    },

    /**
     * Test if website is (stil) open.
     *
     * @param typeOfDelivery Pickup or delivery
     * @return Promise with the true (open) or false (closed)
     */
    isOpenForOrders(typeOfDelivery: TypeOfDelivery): Promise<boolean> {
        return new Promise((resolve, reject) => {
            fetch(`/api/is-open-for-orders?type=${encodeURIComponent(typeOfDelivery)}`)
                .then((response: Response) => {
                    if (response.status !== 200) {
                        reject({
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    response.json()
                        .then(result => resolve(!!result.open))
                        .catch(reject);
                })
                .catch(reject);
        });
    },

    /**
     * Get order by uuid.
     *
     * @param id Order id (long uuid)
     * @param [cached] If true, load the cached version of the order (optional, default false)
     * @return Promise with the order or reject in case of not found / error
     */
    loadOrder(id: string, cached = false): Promise<{order: IOrder, lastModified: Date}|IResponseError|Error> {
        return new Promise((resolve, reject) => {

            if (!UtilityServices.testOrderUUIDFormat(id)) {
                reject({
                    errorCode: 'INVALID_ORDER_ID',
                    errorMessage: `Invalid order id: "${id}"`
                })
                return;
            }

            fetch(`/api/orders/${id}${cached ? '?cached=true' : ''}`)
                .then((response: Response) => {
                    if (response.status === 401) {
                        return reject({
                            errorCode: 'UNAUTHORIZED',
                            errorMessage: 'User is not authorized to receive orders'
                        });
                    }
                    if (response.status === 404) {
                        return reject({
                            errorCode: 'NOT_FOUND',
                            errorMessage: 'The order does not exist'
                        });
                    }
                    if (response.status !== 200) {
                        reject({
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    response.json()
                        .then(resolve)
                        .catch(error => {
                            reject(error);
                        });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    /**
     * Sort given array of gift cards; highest serial number on top.
     *
     * @param giftCards Array of gift cards
     */
    sortGifCards(giftCards: IGiftCard[]): void {
        // noinspection DuplicatedCode
        if (!giftCards || giftCards.length === 0) {
            return;
        }
        const dateLongAgo = new Date(1970, 0, 1);

        giftCards.sort((a, b): number => {
            const creationDateA = a.creationDate || dateLongAgo;
            const creationDateB = b.creationDate || dateLongAgo;
            if (creationDateA < creationDateB) {
                return 1;
            }
            if (creationDateA > creationDateB) {
                return -1;
            }
            return b.serial - a.serial;
        });
    },

    /**
     * Get the GEO location of the restaurant.
     *
     * @return {Promise<PositionAsDecimal|IResponseError|Error>} Promise that resolves with the location
     */
    loadGeoLocationRestaurant(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/api/location')
                .then(response => {
                    if (!response.data) {
                        throw Error(`Expecting to receive a location, but received: "${response.data}"`);
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            return reject({
                                errorCode: 'UNAUTHORIZED',
                                errorMessage: 'User is not authorized to receive the restaurant location'
                            });
                        }
                    }
                    reject(error);
                });
        });
    },

    /**
     * Get sent messages for given phone number (no need to normalize phone number).
     *
     * @param {string} phoneNumber
     * @return {Promise<ISentMessage[]|IResponseError|Error>} Promise that resolves with
     * list of messages or rejects with error
     */
    loadSentMessages(phoneNumber: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(`/api/sentmessages/${phoneNumber.replace(/\s+/gi, '')}`)
                .then(response => {
                    // The response consists of an array of messages.
                    if (!response.data || !Array.isArray(response.data)) {
                        throw Error(`Expecting to receive an array, but received: "${response.data}"`);
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            return reject({
                                errorCode: 'UNAUTHORIZED',
                                errorMessage: 'User is not authorized to receive orders'
                            });
                        }
                    }
                    reject(error);
                });
        });
    },

    /**
     * Get low and high VAT tariffs.
     *
     * @return {Promise<{taxLow:number,taxHigh:number}|Error>} Promise that resolves with low and height VAT tariff
     */
    loadVatTariffs(): Promise<any> {
        return new Promise((resolve, reject) => {
            // Use the cached if available.
            if (config) {
                return resolve(config);
            }
            axios.get('/api/config')
                .then(response => {
                    config = response.data;
                    resolve(config);
                })
                .catch(reject);
        });
    },

    /**
     * Set new custom waiting time.
     *
     * @param {number} newWaitingTime Custom waiting time; 0 to reset
     * @return {Promise<void|Error>} Promise that resolves on success or reject on failure
     */
    setCustomWaitingTime(newWaitingTime: number): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('/api/waiting', {timeCustom: newWaitingTime})
                .then(resolve)
                .catch(reject);
        });
    },

    /**
     * Pickup? (pickup has always a pickup time)
     *
     * @param order The order
     * @return Return string with time (hh:mm) in case the order will be picked up or true/false otherwise
     */
    isPickup(order: IOrder): string|boolean {
        if (!order || !order.typeOfDelivery) {
            throw Error('Cannot determine typeOfDelivery for order: ' + (order ? order.typeOfDelivery : order));
        }
        if (order.typeOfDelivery !== TYPE_OF_DELIVERY_PICKUP) {
            return false;
        }
        // Return the time.
        const pickupTime = order.Bezorgtijd.replace(REGEXP_PICKUP, '');
        return pickupTime ? pickupTime : true;
    },

    /**
     * Determines if the order is originally a Sitedish order.
     *
     * @param {IOrder | undefined} order The order to test
     * @return {boolean} True in case the order is a Sitedish order, false otherwise
     */
    isSitedishOrder(order: any): boolean {
        return !!(order && order.channel === CHANNEL_TYPE_SITEDISH);
    },

    /**
     * Determine the distinct VAT tariffs from the list of dishes.
     * (There's also a copy in NodeJS orders-services.js).
     *
     * @param dishes
     * @param defaultVatTariff The default VAT tariff (currently 9) used for dishes where VAT is undefined
     * @return List of VAT tariffs ordered by amount (the lowest first) or an empty list
     */
    vatTariffs(dishes: IGerecht[], defaultVatTariff: number): number[] {
        if (!dishes) {
            return [];
        }
        if (!defaultVatTariff && defaultVatTariff !== 0) {
            throw Error(`Parameter defaultVatTariff is required: "${defaultVatTariff}"`);
        }
        const tariffs = dishes.map(dish => dish.Gerecht_BTW === undefined ? defaultVatTariff : dish.Gerecht_BTW);
        return [...new Set(tariffs)].sort((a, b) => a - b);
    },

    /**
     * Calculate the total VAT (BTW).
     * (There's also a copy in NodeJS orders-services.js).
     *
     * @param tariff Vat tariff, 21, 9 or 0
     * @param dishes The list of dishes
     * @param defaultVatTariff The default VAT tariff (currently 9) used for dishes where VAT is undefined
     * @return The total VAT as number eg 0.86, or 0
     */
    calculateVat(tariff: number, dishes: IGerecht[], defaultVatTariff: number): number {
        if (!dishes) {
            return 0;
        }
        if (!defaultVatTariff && defaultVatTariff !== 0) {
            throw Error(`Parameter defaultVatTariff is required: "${defaultVatTariff}"`);
        }
        return dishes.reduce((accumulator, dish) => {
            // If "Gerecht_BTW" is undefined, it means the VAT tariff is 9.
            if (tariff === dish.Gerecht_BTW || (tariff === defaultVatTariff && typeof dish.Gerecht_BTW === 'undefined')) {
                const totalString = dish.Gerecht_Subtotaal ? dish.Gerecht_Subtotaal : '0';
                const total = Number.parseFloat(totalString);
                const totalWithoutVat = total / (1 + tariff / 100);
                const vat = total - totalWithoutVat;
                return accumulator + vat;
            } else {
                return accumulator;
            }
        }, 0);
    },

    /**
     * Deliver at requested time?
     *
     * @param order Order to test
     * @return Return string with time (hh:mm) in case the order is a delivery order and has a suggested time
     */
    isDeliverAtSpecificTime(order: IOrder): string|null {
        // Delivery at specific time? Then the contents is only the requested time eg: '16:45'.
        if (!order || !order.Bezorgtijd || order.typeOfDelivery !== TYPE_OF_DELIVERY_DELIVERY) {
            return null;
        }
        return REGEXP_DELIVERY_TIME.test(order.Bezorgtijd) ? order.Bezorgtijd : null;
    },

    /**
     * Deliver as soon as possible?
     *
     * @param order Order to test
     * @return true in case ASAP order, false otherwise.
     */
    isDeliverAsap(order: IOrder): boolean {
        return order?.typeOfDelivery === TYPE_OF_DELIVERY_DELIVERY
            && /Zo snel mogelijk/i.test(order?.Bezorgtijd);
    },

    /**
     * Pickup as soon as possible?
     *
     * @param order Order to test
     * @return true in case ASAP order, false otherwise.
     */
    isPickupAsap(order: IOrder): boolean {
        return order?.typeOfDelivery === TYPE_OF_DELIVERY_PICKUP
            && /Zo snel mogelijk/i.test(order?.Bezorgtijd);
    },

    /**
     * Return delivery or requested delivery/pickup time for given order to show in orders overview.
     *
     * @param order Order
     * @returns Time of delivery to show
     */
    getDeliveryOrPickupTime(order: IOrder): string {
        if (!order) {
            return '';
        }
        // Order is confirmed and has a delivery date.
        if (order.deliveryDate) {
            let time = DateServices.getTimestamp(order.deliveryDate, true);
            if (this.isDeliverAsap(order) || this.isPickupAsap(order)) {
                time += ' (zsm)';
            }
            if (this.isPickup(order)) {
                time += ' (afhaal)';
            }
            return time;
        }

        // Order which is not yet confirmed.
        let time = '';
        if (order.Bezorgtijd) {
            const matches = REGEXP_BEZORGTIJD.exec(order.Bezorgtijd);
            if (matches) {
                time += ' ' + matches[1];
            }
        }
        if (this.isDeliverAsap(order) || this.isPickupAsap(order)) {
            time += ' zsm';
        }
        if (this.isPickup(order)) {
            time += ' (afhaal)';
        }

        return time.trim();
    },

    /**
     * Valid street name?
     *
     * @param street Street name and number
     * @return Return true in case of valid address, false otherwise
     */
    isInvalidAddress(street: string): boolean {
        return !street || !street.trim() || !!street.match(/[0-9]{4,}/g);
    },

    /**
     * When loaded from filesystem/serialized, the date objects are string; convert back to date objects.
     * (function copied from OrdersService.fixOrderDates).
     *
     * @param orders The list of orders
     */
    fixOrderDates(orders: IOrder[]|null): void {
        // noinspection DuplicatedCode
        if (!orders) {
            return;
        }
        for (const order of orders) {
            if (order.date && (order.date as any).substring) {
                order.date = new Date(order.date);
            }
            if (order.deliveryDate && (order.deliveryDate as any).substring) {
                order.deliveryDate = new Date(order.deliveryDate);
            }
        }
    },

    /**
     * Create new order, return the unique id.
     *
     * @param order New order
     * @return Unique id of new order
     */
    createNewOrder(order: IOrder): Promise<string> {
        return new Promise((resolve, reject) => {
            fetch('/api/orders', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                },
                body: JSON.stringify(order)
            })
                .then((response: Response) => {
                    if (response.status !== 200) {
                        reject({
                            status: response.status,
                            errorCode: 'GENERAL_ERROR',
                            errorMessage: `${response.status} ${response.statusText}`
                        });
                        return;
                    }
                    response.text()
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        });
    }
};


