
import PriceFilter from '../components/PriceFilter';
import UCFirstFilter from '../components/UCFirstFilter';
import UtilityServices from '../services/UtilityServices';
import OrderServices from '../services/OrderServices';
import {defineComponent} from 'vue';
import {IGerecht} from "@/interfaces/order";

export default defineComponent({

    props: {
        /**
         * List of dishes or an empty list.
         * @type {IGerecht[]}
         */
        dishes: {
            type: Array,
            required: true
        },
        defaultVatTariff: {
            type: Number,
            required: false
        }
    },
    computed: {
        /**
         * Determine the distinct VAT tariffs from the list of dishes.
         *
         * @return {number[]} List of VAT tariffs or an empty list
         */
        vatTariffs(): number[] {
            if (!this.defaultVatTariff) {
                return [];
            }
            const vatTariffs = OrderServices.vatTariffs(this.dishes as IGerecht[], this.defaultVatTariff);
            // Remove 0% VAT, makes no sense to display.
            if (vatTariffs.length && vatTariffs[0] === 0) {
                vatTariffs.splice(0, 1);
            }
            return vatTariffs;
        },

        /**
         * Calculate the total deposit.
         *
         * @return The total price of all deposit, number of Euro's (float)
         */
        calculatedDeposit(): number {
            if (!this.dishes || this.dishes.length === 0) {
                return 0;
            }
            return (this.dishes as IGerecht[]).reduce<number>((previousValue, dish) =>
                previousValue + (dish.Gerecht_Aantal || 0) * Number.parseFloat(dish.Gerecht_Statiegeld || '0'), 0);
        }
    },
    methods: {

        /**
         * Calculate the total VAT (BTW).
         *
         * @param {number} tariff Vat tariff, 21, 9 or 0
         * @param {IGerecht[]} dishes The list of dishes
         * @return {number|null} The total VAT as number eg 0.86, or null if no dish with given tariff
         */
        calculateVat: OrderServices.calculateVat,

        /**
         * Number of dishes or an empty string.
         *
         * @param {number|Object|undefined} count Number of dishes or an empty object
         * @return {string} Number of dishes or an empty string.
         */
        dishCount(count: number|Object|undefined): string {
            const value = UtilityServices.hideEmpty(count);
            return value ? `${value}.` : '';
        },

        /**
         * Test if the given value is a number.
         * https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number#175787
         *
         * @param {string} value eg '9.95'
         * @return {boolean}
         */
        isNumber(value: string): boolean {
            return !isNaN(parseInt(value, 10));
        },

        // Filters.

        price: PriceFilter.price,
        ucfirst: UCFirstFilter.ucfirst
    }
});

