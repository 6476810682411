// noinspection SpellCheckingInspection
export default {

    /**
     * Capitalize the first character of given text.
     *
     * @param {*} value Text to capitalize
     * @return {string} Capitalized text or empty string
     */
    ucfirst: (value: any) => {
        if (value && `${value}` !== '[object Object]') {
            let text = `${value}`.trim();
            if (text.length < 2) {
                text += '  ';
            }

            return (text.charAt(0).toUpperCase() + text.substr(1)).trim();
        }
        return '';
    }
};
