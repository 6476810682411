import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/IDEAL_Logo-small.png'
import _imports_1 from '../assets/bank-card.svg'
import _imports_2 from '../assets/credit-card.svg'
import _imports_3 from '../assets/paymentrequest-small.png'
import _imports_4 from '../assets/euro-coin-small.png'


const _hoisted_1 = {
  key: 0,
  class: "text-monospace"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.order)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.price(_ctx.order.Totaalbedrag, _ctx.$i18n.locale)) + " ", 1),
        (_ctx.order.Betaling === 'iDeal')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              width: "24",
              alt: "iDeal",
              title: _ctx.$t('orders.labels.payedwithideal')
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.order.Betaling === 'PIN')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _imports_1,
              width: "24",
              alt: "PIN",
              title: _ctx.$t('orders.labels.payedwithpin')
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.order.Betaling === 'creditcard')
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              src: _imports_2,
              width: "24",
              alt: "Creditcard",
              title: _ctx.$t('orders.labels.payedwithcreditcard')
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.order.Betaling === 'paymentrequest')
          ? (_openBlock(), _createElementBlock("img", {
              key: 3,
              src: _imports_3,
              width: "24",
              alt: "Payment request",
              title: _ctx.$t('orders.labels.payedwithpaymentrequest')
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.order.Betaling === 'contant')
          ? (_openBlock(), _createElementBlock("img", {
              key: 4,
              src: _imports_4,
              width: "24",
              alt: "Cash",
              title: _ctx.$t('orders.labels.payedwithcash')
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}