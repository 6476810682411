
import * as geolib from 'geolib';
import CeilFilter from '@/components/CeilFilter';
import {defineComponent} from 'vue';

/**
 * Translation between exact compass positions and rotation in degrees.
 */
const ROTATIONS = {
    'N': 0,
    'NNE': 22.5,
    'NE': 45,
    'ENE': 67.5,
    'E': 90,
    'ESE': 112.5,
    'SE': 135,
    'SSE': 157.5,
    'S': 180,
    'SSW': 202.5,
    'SW': 225,
    'WSW': 247.5,
    'W': 270,
    'WNW': 292.5,
    'NW': 315,
    'NNW': 337.5
};

export default defineComponent({

    props: {
        order: {
            type: Object,
            required: false
        },
        geoLocationRestaurant: {
            type: Object,
            required: false
        }
    },
    methods: {
        // Filters.
        ceil: CeilFilter.ceil
    },
    computed: {
        /**
         * Return the location and delivery time.
         *
         * @returns {[string, number]} Rotation as label (N,NNW,NW) and in degrees (0 = north / 360 degrees)
         */
        compassRotation() {
            if (!this.order || !this.order.geoLocation || !this.geoLocationRestaurant || this.order.typeOfDelivery !== 'delivery') {
                return undefined;
            }
            const compassDirectionLabel = geolib.getCompassDirection(this.geoLocationRestaurant as any, this.order.geoLocation);
            return [compassDirectionLabel, ROTATIONS[compassDirectionLabel] || 0];
        }
    }
});

